<template>
	<div :id="id_" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Change instance settings</h5>
					<button type="button" class="close" @click="hide()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div v-for="field in fields" :key="field.key" class="mb-2">
						<div class="form_title" v-if="field.title">{{field.title}}</div>
						<div class="form_description" v-if="field.description">{{field.description}}</div>
						<div v-if="field.type=='selector'" class="d-flex flex-wrap justify-content-around">
							<div class="card option_card clickable mb-2" v-for="option in field.options" style="border-radius: 15px;" @click="selections[field.key]=option.value" v-bind:key="option.value" v-bind:class="{'active':selections[field.key]==option.value}">
								<div class="card-body text-center" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
									<div style="font-size:20px">{{option.title}}</div>
									<div style="opacity: 0.7;">{{option.costPerHour}}$/hour</div>
									<div style="opacity: 0.7;">CPU: {{option.CPU}}</div>
									<div style="opacity: 0.7;">Memory: {{option.memory}}</div>
								</div>
							</div>
						</div>
						<input type="number" class="form-control" :name="field.key" v-else-if="field.type=='number'" :max="field.max" :min="field.min" v-model="selections[field.key]">
						<input :type="field.type" class="form-control" :name="field.key" v-else v-model="selections[field.key]">
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-success" @click="confirm()">Confirm</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import * as GriffinUtils from '@/utils/griffinUtils.js'
	import * as AdminUtils from '@/utils/adminUtils.js'

	import bootbox from 'bootbox'

	export default {
		name:'ChangeInstance',
		data() {
			return {
				id_:Utils.generateId(),
				user_id:null,
				fields:[],
				selections:{},
				old_selections_values: {},
				resolve:null,
				reject:null
			}
		},
		methods: {
			show(user_id=null) {
				this.user_id = user_id;

				var self = this;
				return new Promise((resolve,reject) => {
					self.resolve = resolve;
					self.reject = reject;
					$('#'+self.id_).modal("show");

					self.loadData();
				})
			},
			hide() {
				$('#'+this.id_).modal("hide");
			},
			loadData() {
				this.fields = [];
				this.selections = {};
				var self = this;
				blockElement('#'+self.id_);
				var resolveBlock = function(result) {
					unblockElement('#'+self.id_);
					self.fields = result.fields;
					self.selections = result.selections;

					self.old_selections_values = Object.assign({}, result.selections);
				};
				var rejectBlock = function(error) {
					unblockElement('#'+self.id_);
					bootbox.alert(error.error);
				};
				if(this.user_id != null) {
					AdminUtils.getInstanceScheme(this.user_id)
					.then(resolveBlock)
					.catch(rejectBlock);
				} else {
					GriffinUtils.getInstanceScheme()
					.then(resolveBlock)
					.catch(rejectBlock);
				}
			},
			confirm() {
				var self = this;
                console.log(self.old_selections_values);
                var message = "Do you really want to save these settings?";
                if (Number(self.old_selections_values['storage']) > Number(self.selections['storage'])){
                    message = message + "<br/> Reduce the storage size will delete all data";
                }
				bootbox.confirm(message, function(result){
					if(result) {
						blockElement('#'+self.id_);
						var resolveBlock = function() {
							unblockElement('#'+self.id_);
							self.hide();
							bootbox.alert("Settings saved successfully");

							if(self.resolve) {
								self.resolve(self.selections);
							}
						};
						var rejectBlock = function(error) {
							unblockElement('#change-instance-modal');
							bootbox.alert(error.error);

							if(self.reject) {
								self.reject(error);
							}
						};
						if(self.user_id != null) {
							AdminUtils.changeInstance(self.user_id, self.selections)
							.then(resolveBlock)
							.catch(rejectBlock);
						} else {
							GriffinUtils.changeInstance(self.selections)
							.then(resolveBlock)
							.catch(rejectBlock);
						}
					}
				});
			}
		}
	}
</script>
<style>
</style>