<template>
    <div>
        <div class="fill container-fluid p-0 text-center">
            <div id="login-card" class="card mx-auto mt-5 text-center" style="width:390px;">
                <div class="card-body">
                    <h5 class="card-title p-3">Welcome to Griffin</h5>
                    <div class="alert alert-danger" v-if="error">
                        {{ error }}
                    </div>
                    <form class="mt-2">
                        <div class="form-group text-left">
                            <strong>E-mail</strong>
                            <input v-model="email" type="email" class="form-control" placeholder="Enter your e-mail address" autocomplete='OFF'>
                        </div>
                        <div class="form-group text-left">
                            <strong>Password</strong>
                            <input v-model="password" type="password" class="form-control" placeholder="Enter your password" autocomplete='OFF' v-on:keyup.enter="login">
                            <div class="text-right"><small class="fake_link" @click="forgotPassword">Forgot your password?</small></div>
                        </div>
                        <button type="button" class="btn btn-success mt-3" @click="login">Sign in</button>
                    </form>
                    <div class="mt-3">
                        New on Griffin? Register by clicking <router-link to="/register">here</router-link>
                    </div>
                </div>
            </div>
        </div>
        <ForgotPassword ref="forgotPassword"></ForgotPassword>
    </div>
</template>

<script>

import * as AccountUtils from '@/utils/accountUtils.js'
import ForgotPassword from '@/modals/forgotPassword'

export default {
    name: 'Login',
    components: {
        ForgotPassword
    },
    data() {
        return {
            error : '',
            email : '',
            password : ''
        }
    },
    methods: {
        login() {
            var self = this;

            this.error = null;
            blockElement('#login-card');
            AccountUtils.signIn(this.email, this.password)
            .then(function(result) {
                if(result['force_change_password'] != null) {
                    self.$router.push({ name : 'ChangePassword' ,params: { email: self.email }});
                } else {
                    unblockElement('#login-card');
                    self.$router.push({ name : 'Main' });
                }
            })
            .catch(function(error) {
                unblockElement('#login-card');
                self.error = error.error;
            });
        },
        forgotPassword() {
            this.$refs.forgotPassword.show();
        }
    }
}
</script>
