<template>
    <div id="forgot-password-modal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Password recovery</h5>
                    <button type="button" class="close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-danger" v-if="error">
                        {{ error.error }}
                    </div>
                    <p>Enter your e-mail address and we will send you the verification code</p>
                    <div class="form-group">
                        <input v-model="email" type="email" class="form-control" placeholder="E-mail address">
                    </div>
                    <div v-if="sendCode">
                        <div class="form-group">
                            <input v-model="code" class="form-control" placeholder="Verification code">
                        </div>
                        <div class="form-group">
                            <input v-model="password" type="password" class="form-control" placeholder="New password">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div v-if="!sendCode">
                        <button type="button" class="btn btn-primary mr-2" @click="sendCode = true">Already have the code</button>
                        <button type="button" class="btn btn-success" @click="confirm()">Submit</button>
                    </div>
                    <div v-else>
                        <button type="button" class="btn btn-success" @click="resetPassword()">Reset password</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import * as AccountUtils from '@/utils/accountUtils.js'

    export default {
    name: 'ForgotPassword',
    data() {
        return {
            email: '',
            error: null,
            sendCode: false,
            code: '',
            password: '',
        }
    },
    methods: {
        clear() {
            this.email = '';
            this.error = null;
            this.sendCode = false;
            this.code = '';
            this.password = '';
        },
        show() {
            this.clear();
            $('#forgot-password-modal').modal("show");
        },
        hide() {
            $('#forgot-password-modal').modal("hide");
        },
        confirm() {
            var self = this;

            this.error = null;
            blockElement('#forgot-password-modal');
            AccountUtils.forgotPassword(this.email)
            .then(function(result) {
                unblockElement('#forgot-password-modal');
                self.sendCode = true;
            })
            .catch(function(error) {
                unblockElement('#forgot-password-modal');
                self.error = error;
            });
        },
        resetPassword() {
            var self = this;

            this.error = null;
            blockElement('#forgot-password-modal');
            AccountUtils.resetPassword(this.email, this.code, this.password)
            .then(function(result) {
                unblockElement('#forgot-password-modal');
                self.hide();
                bootbox.alert("Password reset with success");
            })
            .catch(function(error) {
                unblockElement('#forgot-password-modal');
                self.error = error;
            });
        }
    }
}
</script>