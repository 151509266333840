import * as Connector from '@/utils/connector.js'

export function checkStatus() {
    return new Promise((resolve, reject) => {
        Connector.get('griffin/status')
        .then(resolve)
        .catch(reject);
    });
}

export function start() {
    return new Promise((resolve, reject) => {
        Connector.get('griffin/start')
        .then(resolve)
        .catch(reject);
    });
}

export function user_cost(start = '2021-10-01', end = '2021-11-01') {
    return new Promise((resolve, reject) => {
        Connector.get('griffin/user_cost', {'start_time': start, 'end_time': end})
        .then(resolve)
        .catch(reject);
    });
}

export function stop() {
    return new Promise((resolve, reject) => {
        Connector.get('griffin/stop')
        .then(resolve)
        .catch(reject);
    });
}

export function getUserData() {
    return new Promise((resolve,reject) => {
        Connector.get('griffin/user_data')
        .then(function(result) {
            resolve(result);
        })
        .catch(reject);
    });
}

export function getInstanceScheme() {
    return new Promise((resolve,reject) => {
        Connector.get('griffin/instance_scheme')
        .then(function(result) {
            resolve(result);
        })
        .catch(reject);
    });
}

export function changeInstance(selections) {
    return new Promise((resolve,reject) => {
        Connector.post('griffin/change_instance',{'selections':selections})
        .then(function(result) {
            resolve(result);
        })
        .catch(reject);
    });
}

export function getEnvironmentScheme(user) {
    return new Promise((resolve,reject) => {
        Connector.get('griffin/get_griffin_environment_schema',{
            'user_id' : user
        })
        .then(function(result) {
            resolve(result);
        })
        .catch(reject);
    });
}
