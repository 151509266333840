<template>
	<div>
		<div class="top_area">
		</div>
		<div id="Terms" class="container">
			<h2>Terms of Service</h2>
			<h6>1. Legal Agreement</h6>
			<p>
				These “Terms of Use” constitute a legal agreement (the “Agreement”) between Galaxyadvisors AG, Laurenzenvorstadt 69, CH-5000 Aarau, Switzerland (the “Company”), provider of Griffin, and the party accepting these terms as provided for below (the “Customer”). If the party entering this Agreement is doing so on behalf of his or her employer or another entity, such party represents that he or she has the authority to bind such entity to this Agreement and access the reports and analytics that are produced through use of the Product, in which case the term “Customer” includes and references such employer or entity. If the party entering into this Agreement does not have such authority or does not agree with these terms and conditions, he or she must not accept this Agreement and may not use the Product. <strong>PLEASE REVIEW THIS AGREEMENT CAREFULLY. BY CLICKING “Accept” BELOW, REGISTERING FOR, AND/OR USING THE PRODUCT IN ANY MANNER, CUSTOMER IS AGREEING TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.</strong> 
			</p>

			<blockquote>Please contact us at Galaxyadvisors AG, Laurenzenvorstadt 69, CH-5000 Aarau, Switzerland or by email to: info@galaxyadvisors.com if you have any questions about this Agreement.</blockquote><h6>2. Griffin</h6>

			<p>
				Subject to Customer’s payment of the applicable fees and under the terms of and subject to the restrictions in this Agreement, the Company will provide to Customer the Griffin statistics and analytics tool (the “Product”) purchased by Customer pursuant to an Order Form between Customer and the Company (an “Order Form”) for the term set forth in the Order Form. Customer may use and access the Product in connection with Customer’s compatible electronic mail account. Customer’s rights to use the Product are non-exclusive and non-transferable. The Company may from time to time modify the Product and add, change, or delete features of the Product in its sole discretion. Customer’s continued use of the Product after any such changes to the Product constitutes Customer’s acceptance of these changes.
			</p>

			<h6>3. Customer Access and Acknowledgements</h6>
			<p>
				Depending upon the version of the Product selected by Customer, the Company may require domain-wide access to all email accounts in the domain, or to specific email accounts, registered by Company for use with the Product in order to process the metadata of such emails (including headers, but excluding the actual contents and attachments of such emails). Customer acknowledges and agrees that it has all necessary rights, consents, and authority to use the Product with respect to all email accounts registered by Customer to be used with the Product, including, without limitation, the right to access all of the email information and resulting reports, analytics, and other metrics associated with such accounts. When using the Product for an entire domain, Customer’s email service administrator, on behalf of Customer, acknowledges that he or she is allowed to do so, and is also allowed to access the Product and resulting information and the Product will have access to the email metadata of all accounts within the domain, unless the administrator takes further steps to limit the access to a subset of users. Company agrees that the email header information obtained by the Company hereunder will be used by the Company solely to the extent necessary to provide the Product to Customer.
			</p>

			<p>
				Customer further acknowledges and agrees that Customer is solely responsible for: (a) all use of the Product by Customer and any other party utilizing Customer’s email account(s), with or without authorization; (b) maintaining the confidentiality of Customer’s email account(s) and password(s); and (c) ensuring that Customer’s use of the Product and Customer’s email account(s) complies with all applicable Federal, state, and local privacy and data security rules, regulations and laws. Customer further acknowledges and agrees that the Company has no control over and shall not be responsible for any changes by Customer’s email provider to the email application, including, without limitation, such changes which may affect the Product, and Customer shall indemnify and hold the Company harmless with respect to any losses, damages, or claims arising therefrom, pursuant to Section 14 below.
			</p>

			<h6>4. Free Trial</h6>
			<p>
				If Customer registers for a free trial, the Company will make the Product available to Customer on a trial basis free of charge until the earlier of (a) the end of the free trial period for which Customer registered to use the applicable Product, or (b) the start date of any purchased subscriptions ordered by Customer for the Product. Additional trial terms and conditions may appear on the trial registration web page. Any such additional terms and conditions are incorporated into this Agreement by reference and are legally binding.
			</p>

			<p>
				<strong>CUSTOMER DATA ON THE COMPANY’S SYSTEMS OR IN THE COMPANY’S POSSESSION OR CONTROL, CUSTOMER REPORTS, AND ANY CUSTOMIZATIONS MADE TO THE PRODUCT BY OR FOR CUSTOMER DURING THE FREE TRIAL MAY BE PERMANENTLY LOST OR DELETED AT THE END OF THE FREE TRIAL PERIOD UNLESS CUSTOMER PURCHASES THE SAME PRODUCT AS THOSE COVERED BY THE TRIAL OR PURCHASES AN UPGRADED PRODUCT BEFORE THE END OF THE TRIAL PERIOD. NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, DURING THE FREE TRIAL THE PRODUCT IS PROVIDED “AS-IS” WITHOUT ANY REPRESENTATIONS OR WARRANTIES.</strong>
			</p>

			<h6>5. Fees</h6>
			<p>
				Customer shall pay the Company the applicable fees as set forth in an order form issued by the Company and accepted by Customer (an “Order Form”). Unless otherwise set forth on such Order Form, fees shall be payable within thirty (30) days after the date of Company’s invoice. In the event Customer fails to make payment of the applicable fees when due, the Company reserves the right to suspend access to the Product or terminate this Agreement and Customer’s right to use the Product. Customer will pay the Company interest at the rate of one percent (1%) per month or the maximum amount permitted by applicable law, whichever is less, on any undisputed amount not fully paid within ten (10) days of its due date until any such amount is fully paid. If Customer breaches its obligation to make timely payment as provided hereunder, Customer agrees to pay all of the Company’s costs of collection, including, without limitation, all court costs and attorneys’ fees. In the event Customer has obtained the Product without fees or expense, the foregoing provisions of this Section 5 shall not apply, and the Company shall have the right to suspend access to the Product or terminate this Agreement and Customer’s right to use the Product at any time and without liability to Customer.
			</p>

			<h6>6. Customer’s Representations and Warranties</h6>
			<p>
				Customer represents and warrants to the Company that Customer (i) has full power and authority to enter into this Agreement, and if the party entering into this Agreement is doing so on behalf of an entity, such party has the full legal authority to bind such entity to this Agreement; (ii) will not violate any local, state, national or international law or regulation in connection with use of the Product, or otherwise use the Product in any way that is in furtherance of criminal, fraudulent, or other unlawful activity; (iii) will not interfere with or disrupt the Product; (v) will not violate any codes of conduct, requirements, terms of use, policies or regulations related to the Product; (vi) will not interfere with or attempt to interfere with any other person’s use of the Product; and (vii) will not use the Product to send or otherwise make available any viruses, Trojan horses, worms, corrupted files, or any other similar software that may damage the operation of another’s computer or property.
			</p>

			<h6>7. Company’s Representations and Limited Warranties </h6>
			<p>
				The Company represents and warrants to Customer that the Company: (i) has the full power and authority to enter into this Agreement; (ii) will not violate any local, state, national or international law or regulation in connection with the provision of the Product; and (iii) will provide the Product in accordance with this Agreement subject to the terms and conditions hereof.
			</p>

			<h6><strong>8. DISCLAIMERS</strong></h6>
			<p>
				<strong>EXCEPT AS PROVIDED FOR IN SECTION 7 ABOVE, THE COMPANY MAKES NO OTHER REPRESENTATIONS OR WARRANTIES OF ANY KIND WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE; ALL SUCH REPRESENTATIONS AND WARRANTIES INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, ACCURACY, TIMELINESS, COMPLETENESS, TITLE AND NON-INFRINGEMENT, ARE HEREBY EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT ANY REPORTS, ANALYSIS, OR INFORMATION MADE ACCESSIBLE BY OR THROUGH THE PRODUCT, OR THAT USE OF THE PRODUCT OR ANY PORTION THEREOF WILL BE WITHOUT FAILURES, UNINTERRUPTED, OR ERROR-FREE, WILL BE ACCESSIBLE IN ANY PARTICULAR HARDWARE/SOFTWARE ENVIRONMENT, OR WILL MEET CUSTOMER’S REQUIREMENTS. THE PRODUCT IS NEITHER DESIGNED NOR INTENDED FOR HIGH-RISK ACTIVITIES.</strong>
			</p>

			<p>
				Under no circumstances will the Company be held liable for any loss of Customer’s email, data, records, or other materials. Customer acknowledges that the Company has no control over the functioning of the Internet, G Suite, Gmail, or any other email provider, email service, or third-party applications used in connection with the Product, and the Company makes no representations or warranties of any kind regarding the performance of the Internet or any such third-party applications.
			</p>

			<h6>9. Customer’s Covenants</h6>
			<p>
				Customer acknowledges and agrees: (i) to comply with all applicable laws and regulations applicable to use of the Product including, but not limited to, laws and regulations regarding data collection and privacy; (ii) to the terms of the Company’s privacy policy, which is located at <a href="policy">{{privacyUrl()}}</a> (the “Privacy Policy”) and is incorporated herein by reference (including any changes to such privacy policy made in accordance with its terms); (iii) to be solely responsible for Customer’s use of the Product; (iv) that the Product is strictly a tool to be used in conjunction with good and reasonable judgment by competent personnel; and (v) to comply with all relevant export laws and regulations, including, but not limited to, the U.S. Export Administration Regulations and Executive Orders (“Export Controls”); Customer warrants that it is not a person, company or destination restricted or prohibited by Export Controls.
			</p>

			<h6>10. Intellectual Property</h6>
			<p>
				Customer agrees that the Company owns all intellectual property rights in and to the Product including but not limited to all software, scripts, programming and comparable materials used therein or related thereto (“Software”), trademarks, trade secrets, look and feel, structure, organization, design, algorithms, templates, data models, logic flow, text, graphics, logos, and screen displays, and any other proprietary rights associated therewith. Customer shall not copy, modify, reverse engineer, decompile or disassemble the Software, or otherwise attempt to reconstruct or discover the source code for the Software. Customer further agrees not to resell, lease, assign, distribute, time share or otherwise commercially exploit or make the Product available to any third party for such third party’s benefit. The Company reserves all rights in the Product not expressly granted to Customer hereunder. The Company shall have a royalty-free, worldwide, transferable, and perpetual license to use or incorporate into the Product any suggestions, ideas, enhancement requests, feedback, or other information provided by Customer relating to the Product.
			</p>

			<h6>11. Technical Support Product</h6>
			<p>
				The Company may sell maintenance, technical, or other support for the Product or the Software pursuant to a separate agreement between the Customer and the Company. Except to the extent otherwise agreed by the Company in a separate written agreement with Customer, the Company expressly disclaims any obligation to provide updates, upgrades, bug fixes, patches or any similar service and Customer acknowledges that the Company has no express or implied obligation to do so and are not entering into this Agreement in reliance on any of the foregoing services. The Company may, from time to time, provide bug fixes for bugs or other; provided, however, in doing so the Company does not obligate itself to do so in future.
			</p>

			<h6>12. Term and Termination</h6>
			<p>
				(a) <em>Term</em>. This Agreement will be effective as of the earlier of: (a) the date Customer accepts this Agreement, or (b) the date the Customer first accesses or uses the Product, and, unless sooner terminated as herein provided, will continue until the term set forth in all active Order Forms hereunder have expired or been terminated.
			</p>

			<p>
				(b) <em>Termination for Convenience</em>. The Company may discontinue providing the Product and terminate this Agreement at any time in its sole discretion and without notice to Customers receiving the free version of the Product. For Customers under a paid subscription, the Company will provide thirty (30) days’ prior notice of such termination and will refund Customer on a prorated basis for any fees prepaid by the Customer for the unused remainder of the term following the effective date of such termination. Customer may discontinue its use of the Product and terminate this Agreement at any time in its sole discretion by providing the Company with at least thirty (30) days’ prior written notice of its intent to so terminate. In the event of Customer’s termination for convenience, Customer will not be entitled to a refund of any fees paid and Customer agrees to pay the Company any unpaid fees covering the remainder of the term set forth in the applicable Order Form within thirty (30) days after the effective date of such termination.
			</p>

			<p>
				(c) <em>Termination for Breach</em>. Either party may terminate this Agreement (i) thirty (30) days after providing written notice to the other party of a material breach of its obligations under this Agreement if such breach remains uncured at the expiration of such 30-day period, or (ii) immediately upon written notice if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors. Any such termination shall relieve the parties of all rights, obligations and liabilities to the other except, in the case of termination by the Company for Customer’s breach, for the payment of fees due by Customer for the remainder of the term set forth in the applicable Order Form, and except as provided for in Sections 8, 9, 10 and 12 through 17, which Sections shall survive the termination of this Agreement. In no event will termination relieve Customer of its obligation to pay any fees payable to the Company for the period prior to the effective date of termination.
			</p>

			<h6>13. Confidentiality and Security</h6>
			<p>
				“Confidential Information” means any information or data that is disclosed by one party to the other party pursuant to this Agreement that is marked as confidential or that such party does not generally make available to the public. Confidential Information does not include information that the receiving party can show: (a) is or becomes publicly known or available without breach of this Agreement; (b) is received by a receiving party from a third party without breach of any obligation of confidentiality; or (c) was previously known by the receiving party as shown by its written records. A receiving party agrees: (a) to hold the disclosing party’s Confidential Information in confidence, and to protect the disclosing party’s Confidential Information in the same manner that it protects the confidentiality of its own similar confidential information (but in no event using less than reasonable care); and (b) except as expressly authorized by this Agreement, not to, directly or indirectly, use, disclose, copy, transfer or allow access to the disclosing party’s Confidential Information. Without limiting the foregoing, Customer shall disclose and allow access to the Product only for the purpose of supporting and augmenting Customer’s use of the Product. Notwithstanding the foregoing, a receiving party may disclose Confidential Information of the disclosing party as required by law, applicable regulatory authorities, or court order; in such event, such party shall use its best efforts to inform the other party prior to any such required disclosure<strong>.</strong>
			</p>

			<h6>14. Indemnification</h6>
			<p>
				Customer, at its expense, shall indemnify, defend and hold the Company and its officers, directors, owners, employees, and affiliates harmless from and against any and all liability, damages, injuries, losses, costs and expenses (including attorney’s fees) arising out of or relating to Customer’s use of the Product, including but not limited to liability, damages, injuries, losses, costs and expenses arising from any claims relating to Customer’s breach of any representations, warranties, or covenants in this Agreement.
			</p>

			<h6><strong>15. Liability</strong></h6>
			<p>
				<strong>IF CUSTOMER HAS PAID FEES FOR THE PRODUCT IN ACCORDANCE WITH SECTION 5 ABOVE, IN NO EVENT SHALL THE COMPANY’S LIABILITY TO CUSTOMER FOR DAMAGES RESULTING FROM ANY CLAIMS ARISING FROM OR RELATING TO THIS AGREEMENT AND/OR USE OF THE PRODUCT, WHETHER CAUSED BY FAILURE TO DELIVER, NONPERFORMANCE, DEFECTS, BREACH OF WARRANTY OR OTHERWISE, EXCEED THE FEES PAYABLE TO THE COMPANY UNDER THIS AGREEMENT, AND THE COMPANY SHALL NOT BE LIABLE TO CUSTOMER FOR ANY LOST PROFITS OR REVENUES, LOSS OF USE, LOSS OF DATA OR COSTS OF PROCUREMENT OF SUBSTITUTE GOODS, LICENSES OR SERVICES OR SIMILAR ECONOMIC LOSS, OR FOR ANY PUNITIVE, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR SIMILAR DAMAGES OF ANY NATURE, WHETHER FORESEEABLE OR NOT, UNDER ANY WARRANTY OR OTHER RIGHT PROVIDED HEREUNDER OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH THE PERFORMANCE OR NONPERFORMANCE OF THIS AGREEMENT AND/OR THE PRODUCT, OR FOR ANY CLAIM MADE BY A THIRD PARTY REGARDLESS OF WHETHER THE LIABLE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH CLAIM OR DAMAGES.</strong>
			</p>

			<p>
				<strong>In the event Customer has obtained the Product without fees or expense, the Customer acknowledges and agrees that THE COMPANY shall have no liability whatsoever (whether in contract, tort, negligence, strict liability in tort, or by statute or under any other theory of law) to Customer or to any third party concerning performance or non-performance by THE COMPANY, or in any manner related to this Agreement or the Product. Customer further acknowledges and agrees that the exclusion of liability provided for in this paragraph is a reasonable limitation, and granted in consideration, of the fact Customer has obtained the Product without fees or expense. The exclusion of liability in this Section represents the agreed and bargained for understanding of the parties and the absence of compensation to THE COMPANY for the Product reflects such understanding. In the event the foregoing exclusion of liability is unenforceable under any applicable law, the parties further agree that in no event shall THE COMPANY’s aggregate liability to Customer exceed $100, nor shall THE COMPANY be liable for special, consequential, incidental, indirect or punitive loss, damage or expenses whether arising in contract or tort (including but not limited to lost profits, loss of data, or the cost of recreating lost data), even if it has been advised of their possible existence. These limitations of liability will apply notwithstanding any failure of essential purpose of any limited remedy.</strong>
			</p>

			<h6>16. Dispute Resolution. </h6>
			<p>
				The parties agree to work together in good faith to resolve any dispute regarding this Agreement internally and by escalating it to higher levels of management and optional mediation, prior to resorting to binding arbitration. Any dispute, controversy or claim arising out of or relating to this Agreement, or the breach, termination or invalidity thereof, that cannot be resolved by good faith negotiations shall be finally settled by binding arbitration conducted in the English language in Switzerland, under the commercial arbitration rules of the Swiss Arbitration Association (“ASA”). The prevailing party shall be entitled to an award of reasonable attorney fees incurred in connection with the arbitration in such amount as may be determined by the arbitrator. The award of the arbitrator shall be the sole and exclusive remedy of the parties and shall be enforceable in any court of competent jurisdiction. Notwithstanding anything contained in this Section to the contrary, each party shall have the right to institute judicial proceedings against the other party or anyone acting by, through or under such other party, in order to enforce the instituting party’s rights hereunder through specific performance, injunction or similar equitable relief. This Agreement shall be interpreted, construed, and governed by the laws of Switzerland, without regard to its conflict of law provisions. The United Nations Convention on Contracts for the International Sale of Goods shall not apply to this Agreement.
			</p>

			<h6>17. Miscellaneous. </h6>
			<p>
				Neither party shall be liable for any failure or delay in the performance of its obligations (except for payment obligations hereunder) due to causes beyond the reasonable control of the party affected, including but not limited to war, sabotage, insurrection, riot or other act of civil disobedience, strikes or other labor shortages, act of any government affecting the terms hereof, acts of terrorism, accident, fire, explosion, flood, hurricane, severe weather or other act of God, failure of telecommunication or internet service providers.
			</p>

			<p>
				This Agreement and the Privacy Policy constitutes the entire understanding of the parties with respect to its subject matter, and supersedes all prior or contemporaneous written and oral communications, understandings or agreements with respect to its subject matter. No waiver of any provision of this Agreement, or of any rights or obligations of any party hereunder, will be effective unless in writing and signed by the party waiving compliance. The failure by any party to exercise any right provided herein shall not be deemed a waiver or forfeiture of any such right. Headings used in this Agreement are for convenience of reference only and shall not be deemed a part of this Agreement.
			</p>

			<p>
				Customer shall have no right to assign this Agreement or any of Customer’s rights or obligations hereunder. The Company may assign this Agreement and any of its rights hereunder to third parties. Every provision of this Agreement is intended to be severable. If any section of this Agreement is found to be invalid or unenforceable, then such section will be deemed amended and interpreted, if possible, in a way that renders it enforceable. If such an interpretation is not possible, then the section will be deemed removed from this Agreement and the rest of this Agreement will remain in full force and effect.
			</p>

			<h6>18. Amendment. </h6>
			<p>
				The Company reserves the right, in its sole discretion, to modify or change this Agreement at any time by posting the changes to its website or through electronic notice to Customer. Customer’s continued use of the Product following the posting or notice of such changes constitutes acceptance of those changes. The Company will use reasonable commercial efforts to provide notice of material changes to Customer.
			</p>

		</div>
	</div>
</template>
<script>
	export default {
		name:"Terms",
		data() {
			return {

			}
		},
		methods: {
			privacyUrl() {
				return document.location.origin+"/policy";
			}
		}
	}
</script>