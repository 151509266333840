<template>
    <div class="fill container-fluid p-0">
        <div id="register-card" class="card mx-auto mt-5" style="width:390px;">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="icon_button" @click="back()">
                        <i class="fa fa-angle-left"></i>
                    </div>
                    <h5 class="card-title mb-2">Registration</h5>
                </div>

                <div class="alert alert-danger" v-if="error">
                    {{ error }}
                </div>
                <form class="mt-2">
                    <div class="form-group">
                        <strong>Name</strong>
                        <input v-model="name" type="name" class="form-control" placeholder="Enter your name">
                    </div>
                    <div class="form-group">
                        <strong>E-mail</strong>
                        <input v-model="email" type="email" class="form-control mb-1" placeholder="Enter your e-mail address">
                    </div>
                    <div class="form-group">
                        <strong>Password</strong>
                        <input v-model="password" type="password" class="form-control mb-1">
                        <small class="form-text text-muted">at least 8 characters with lowercase, uppercase and special characters</small>
                    </div>
                    <button type="button" class="btn btn-success mt-2" @click="createAccount">Sign up</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import * as AccountUtils from '@/utils/accountUtils.js'

export default {
    name: 'Register',
    data() {
        return {
            error : '',
            name : '',
            email : '',
            password : '',
            code : '',
            message: '',
        }
    },
    mounted() {
    },
    methods: {
        back() {
            this.$router.push({ name : 'Login' });
        },
        createAccount() {
            var self = this;
            
            this.error = null;
            blockElement('#register-card');
            AccountUtils.signUp(this.name, this.email, this.password)
            .then(function(result) {
                unblockElement('#register-card');
                if(!result.userConfirmed) {
                    self.$router.push({ name : 'ConfirmSignup', params: { email: self.email } });
                } else {
                    //login
                }
            })
            .catch(function(error) {
                unblockElement('#register-card');
                self.error = error.error;
            });
        }
    }
}
</script>
