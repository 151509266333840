<template>
    <div class="fill container-fluid p-0">
        <div id="confirm-card" class="card mx-auto mt-5" style="width:390px;">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="icon_button" @click="back()">
                        <i class="fa fa-angle-left"></i>
                    </div>
                    <h5 class="card-title mb-2">Confirm registration</h5>
                </div>
                <div class="alert alert-danger" v-if="error">
                    {{ error.message }}
                </div>
                <div class="alert alert-success" v-if="message">
                    {{ message }}
                </div>
                <div v-if="!success">
                    <p>
                        Enter the code we sent to your email
                    </p>
                    <form class="mt-2">
                        <div class="form-group">
                            <strong>E-mail</strong>
                            <input v-model="email" class="form-control" placeholder="Enter your e-mail address">
                        </div>
                        <div class="form-group">
                            <strong>Verification Code</strong>
                            <input v-model="code" class="form-control" placeholder="Enter the verification code">
                        </div>
                        <button type="button" class="btn btn-success mt-2" @click="confirmCode">Confirm</button>
                        <button type="button" class="btn btn-info mt-2 ml-2" @click="resendCode">Resend code</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import * as AccountUtils from '@/utils/accountUtils.js'

export default {
    name: 'ConfirmSignup',
    data() {
        return {
            error : '',
            message: '',
            email : '',
            code : '',
            success: false
        }
    },
    mounted() {
        if(this.$route.params.email)
            this.email = this.$route.params.email;
    },
    methods: {
        back() {
            this.$router.push({ name : 'Login' });
        },
        confirmCode() {
            var self = this;
            this.error = null;
            this.message = null;
            blockElement('#confirm-card');
            AccountUtils.confirmSignUp(this.email, this.code)
            .then(function() {
                unblockElement('#confirm-card');
                self.success = true;
                self.message = "You account is confirmed. You can sign in now."
            })
            .catch(function(error) {
                unblockElement('#confirm-card');
                self.error = error;
            });
        },
        resendCode() {
            var self = this;
            this.error = null;
            this.message = null;
            blockElement('#confirm-card');
            AccountUtils.resendSignUp(this.email)
            .then(function(result) {
                unblockElement('#confirm-card');
                self.message = "An email with the veritifcation code was sent to "+result.CodeDeliveryDetails.Destination;
            })
            .catch(function(error) {
                unblockElement('#confirm-card');
                self.error = error;
            });
        }
    }
}
</script>
