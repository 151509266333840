<template>
    <div class="row">
        <div class="col-6 mb-2" v-bind:class="{'col-12' : (status!=2)}" v-bind:style="[(status==2) ? {'padding-right':'4px!important'} : {}]">
            <div class="menu_option">
                <div v-if="status == 0" @click="startPressed">
                    <i class="fa fa-play icon-gradient bg-night-fade"></i>
                    <label>
                        Start Griffin
                    </label>
                </div>
                <div v-else-if="status == 1">
                    <div class="spinner-border mx-auto" style="width: 3rem; height: 3rem;">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <label>
                        {{ statusMessage }}
                    </label>
                </div>
                <a v-else-if="status == 2" v-bind:href="url" target="_blank">
                    <i class="fa fa-share icon-gradient bg-night-fade"></i>
                    <label>
                        Open Griffin
                    </label>
                </a>
            </div>
        </div>
        <div class="col-6 mb-2" v-if="status == 2" style="padding-left:4px!important">
            <div class="menu_option" @click="stopPressed">
                <i class="fa fa-stop icon-gradient bg-night-fade"></i>
                <label>
                    Stop Griffin
                </label>
            </div>
        </div>
        <div class="col-6 mb-2" style="padding-right:4px!important">
            <div class="menu_option" @click="settings">
                <i class="fa fa-cog icon-gradient bg-night-fade"></i>
                <label>
                    Settings
                </label>
            </div>
        </div>
        <div class="col-6 mb-2" style="padding-left:4px!important">
            <div class="menu_option" @click="signOut">
                <i class="fa fa-sign-out-alt icon-gradient bg-night-fade"></i>
                <label>
                    Sign out
                </label>
            </div>
        </div>
        <div class="col-12 mb-2" v-if="admin">
            <div class="menu_option" @click="adminPanel">
                <i class="fa fa-solar-panel icon-gradient bg-night-fade"></i>
                <label>
                    Admin panel
                </label>
            </div>
        </div>
    </div>
</template>
<script>

import * as AccountUtils from '@/utils/accountUtils.js'
import bootbox from 'bootbox'
import * as GriffinUtils from '@/utils/griffinUtils.js'
import EventBus from '@/utils/eventBus.js'

export default {
    name: 'Menu',
    data() {
        return {
            status : 1,
            statusMessage : "Loading Griffin status",
            url : null,
            waitingToStart : false,
            timer:null,
            admin:false
        }
    },
    mounted() {
        Notification.requestPermission();
        this.checkStatus();

        this.admin = AccountUtils.isAdmin();
    },
    unmounted() {
        this.stopTimer();
    },
    methods: {
        stopTimer() {
            if(this.timer) {
                clearTimeout(this.timer);
            }
        },
        signOut() {
            var self = this;
            bootbox.confirm("Do you really want to sign out?", function(result){
                if(result) {
                    AccountUtils.signOut();
                    self.$router.push({ name : 'Login' });
                }
            });
        },
        checkStatus() {
            var self = this;
            EventBus.$emit('MAIN_ERROR', null);
            GriffinUtils.checkStatus()
            .then(function(result) {
                unblockElement('body');
                self.updateStatus(result);
            })
            .catch(function(err) {
                self.checkStatusTimeout();
                unblockElement('body');
                EventBus.$emit('MAIN_ERROR', error.error);
            })
        },
        updateStatus(result) {
            this.status = result['status'];
            this.statusMessage = result['info'];
            if(result['url'] != null) {
                this.url = result['url']['url'];
            } else {
                this.url = null;
            }
            if(this.status == 2) {
                if(this.waitingToStart) {
                    if (Notification.permission === "granted") {
                        var notification = new Notification("Griffin is ready");
                    }
                }
                this.waitingToStart = false;
            } else if(this.status != 0) {
                this.checkStatusTimeout();
            }
        },
        checkStatusTimeout() {
            var self = this;
            this.waitingToStart = true;
            this.stopTimer();

            this.timer = setTimeout(function() {
                self.checkStatus();
            }, 1000*20);
        },
        startPressed() {
            var self = this;
            EventBus.$emit('MAIN_ERROR', null);
            blockElement('body');
            GriffinUtils.start()
            .then(function(result) {
                self.checkStatus();
            })
            .catch(function(error) {
                unblockElement('body');
                EventBus.$emit('MAIN_ERROR', error.error);
            });
        },
        stopPressed() {
            var self = this;
            EventBus.$emit('MAIN_ERROR', null);
            blockElement('body');
            GriffinUtils.stop()
            .then(function(result) {
                self.checkStatus();
            })
            .catch(function(error) {
                unblockElement('body');
                EventBus.$emit('MAIN_ERROR', error.error);
            });
        },
        settings() {
            this.$router.push({ name: 'Settings'});
        },
        adminPanel() {
            this.$router.push({ name: 'AdminPanel'});
        }
    }
}
</script>