import * as Connector from '@/utils/connector.js'

export function listUsers() {
    return new Promise((resolve, reject) => {
        Connector.get('admin/list_users')
        .then(resolve)
        .catch(reject);
    });
}

export function getUserCost(start = '2021-10-01', end = '2021-11-01') {
    return new Promise((resolve, reject) => {
        Connector.get('admin/get_users_costs', {'start_time': start, 'end_time': end})
        .then(resolve)
        .catch(reject);
    });
}

export function userCost(user, start = '2021-10-01', end = '2021-11-01') {
    return new Promise((resolve, reject) => {
        Connector.get('admin/get_total_costs', {'user_id': user, 'start_time': start, 'end_time': end})
        .then(resolve)
        .catch(reject);
    });
}

export function submitGriffinEnvironment(user, variables) {
    return new Promise((resolve, reject) => {
        Connector.post('admin/set_griffin_environment_variables', {'user_id': user, 'environment': variables})
        .then(resolve)
        .catch(reject);
    });
}

export function chargeUser(user, amount) {
    return new Promise((resolve, reject) => {
        Connector.get('admin/add_credits', {'user_id': user, 'credits': amount})
        .then(resolve)
        .catch(reject);
    });
}

export function getInstanceScheme(user) {
    return new Promise((resolve,reject) => {
        Connector.get('admin/instance_scheme',{
            'user_id' : user
        })
        .then(function(result) {
            resolve(result);
        })
        .catch(reject);
    });
}

export function changeInstance(user, selections) {
    return new Promise((resolve,reject) => {
        Connector.post('admin/change_instance',{
            'user_id' : user,
            'selections':selections
        })
        .then(function(result) {
            resolve(result);
        })
        .catch(reject);
    });
}

export function getEnvironmentScheme(user) {
    return new Promise((resolve,reject) => {
        Connector.get('admin/get_griffin_environment_schema',{
            'user_id' : user
        })
        .then(function(result) {
            resolve(result);
        })
        .catch(reject);
    });
}

export function upgradeUser(user) {
    return new Promise((resolve,reject) => {
        Connector.get('admin/upgrade_user',{
            'user_id' : user
        })
        .then(function(result) {
            resolve(result);
        })
        .catch(reject);
    });
}

export function downgradeUser(user) {
    return new Promise((resolve,reject) => {
        Connector.get('admin/downgrade_user',{
            'user_id' : user
        })
        .then(function(result) {
            resolve(result);
        })
        .catch(reject);
    });
}

