<template>
	<div class="landing_body">
		<div class="top_header">
			<div class="container">
				<div class="d-flex align-items-center">
					<a class="d-flex align-items-center" href="/" style="color: unset!important;text-decoration: none!important;">
						<img class="mr-2" src="../assets/images/griffin_logo.png" style="width: 20px; height: 20px;">
						<div class="top_title">Griffin</div>
					</a>
					<a href="/login" class="login_link ml-auto">
						<span v-if="!logged">Login</span>
						<span v-else>Enter</span>
					</a>
				</div>
			</div>
		</div>
		<router-view></router-view>
		<footer class="footer page-footer font-small blue">
			<div class="container-fluid text-center text-md-left">
				<div class="row">
					<div class="col-md-6 mt-md-0 mt-3">
						<h5 style="title">Galaxyadvisors AG</h5>
						<span class="text-sub">
							Laurenzenvorstadt 69<br>
							CH-5000 Aarau, Switzerland<br>
						</span>
						<span class="phone"><i class="fas fa-envelope"></i>info@galaxyadvisors.com</span>
					</div>
					<div class="ml-auto mr-5 mt-auto mb-2">
						<a href="terms-of-service" target="_self" class="mr-2">Terms of use</a>
						<a href="policy" target="_self">Privacy policy</a>
					</div>
				</div>
			</div>
			<div class="footer_copyright text-center">
				<a href="http://www.galaxyadvisors.com/" target="blank">Galaxy Advisors</a><br>
				<span class="text-sub">Copyright 2022. All rights reserved.</span>
			</div>
		</footer>
	</div>
</template>
<script>

	import * as AccountUtils from '@/utils/accountUtils.js'

	export default {
		name: 'Landing',
		data() {
			return {
				logged:false,
				user:null
			}
		},
		mounted() {
			var self = this;
			AccountUtils.checkSession()
			.then(function() {
				self.logged = true;
				self.user = AccountUtils.user;
			})
			.catch(function(err) {
				self.logged = false;
			});
		},
		methods: {
			
		}
	}
	
</script>
<style>
	.back-theme {
		background: #ecdbb6;
	}
	.first-gradient-font {
		background: linear-gradient(90deg, #ffa64d, #006dd9);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.second-gradient-font {
		background: linear-gradient(90deg, #0080ff, #59b200);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.section {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
	.section_title {
		color: #0d1b3e;
		font-size: 2.5rem;
		margin-bottom: 2.5rem;
		font-weight: 500;
		line-height: 1.2;
	}
	.section_paragraph {
		margin-bottom: 2.5rem;
		font-size:  1.2rem;
	}
	.section_sub {
		font-size: 1.2rem;
		font-weight: bold;
		margin-bottom: 0.5rem;
	}
	.top_header {
		position: absolute;
		top: 0;
		/*z-index: 9999;*/
		width: 100%;
		padding: 1.2rem;
	}
	.top_title {
		font-weight: bolder;
		font-size: 1.2rem;
	}
	.top_area {
		padding-top: 100px;
		padding-bottom: 100px;
		background: linear-gradient(180deg,#ecdbb6,#ecdbb6,#ecdbb6,#fff);
	}
	.start_button {
		padding: 1.2rem;
		border-radius: 5px;
		border-color: #0080ff;
		border-width: 2px;
		border-style: solid;
		font-size: 1.1rem;
		color: #0080ff;
		display: inline-block;
		cursor: pointer;
		transition: background-color 0.5s ease;
	}
	.start_button:hover {
		background-color: #0080ff;
		color: #fff;
	}
	.card_item {
		padding: 1.2rem;
		box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
		border-radius: 5px;
	}
	.background_orange {
		background: linear-gradient(90deg, #ff9326, #ffa64d);
		color: #fff;
	}
	.background_blue {
		background: linear-gradient(90deg, #006dd9, #0080ff);
		color: #fff;
	}
	.background_green {
		background: linear-gradient(90deg, #59b200, #85b200);
		color: #fff;
	}
	.card_item_title {
		font-weight: bold;
		font-size: 1rem;
		margin-bottom: 0.2rem;
	}
	.card_item_info {

	}
	.footer {
		padding-top: 2rem;
		background-color: #ecdbb6;
	}
	.footer_copyright {
		margin-top: 2rem;
		background-color: #ebd4a3;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	.footer_texture {
		margin-top: 1rem;
		background-image: url('../assets/images/texture.png');
		padding: 24px;
	}
	.phone {
		color: #4285f4;
	}
	.mok_image {
		box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; 
		border-radius: 10px;
		width: 75%;
		cursor: pointer;
		margin: auto;
	}
	.chip_button {
		width: 20px;
		height: 20px;
		border-radius: 10px;
		background: #d8dde6;
		transition: all .2s ease;
		-webkit-transition: all .2s ease;
		cursor: pointer;
	}

	.chip_button.active {
		background: #ffa64d;
	}

	.big_image {
		z-index: 9999;
		background-color: #0008;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		padding: 2rem;
		flex-direction: column;
	}
	.big_image img {
		box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; 
		border-radius: 10px;
		margin-left: auto;
		margin-right: auto;
		width: 75%;
		margin-left: auto;
		margin-right: auto;
	}
	.flex_even {
		flex: 1;
	}
	.flex-container {
		display: flex !important;
	}
	.flex-revert {
		flex-direction: row-reverse;
	}
	.only-mobile {
		display: none;
	}
	.no-mobile {
		display: unset;
	}
	@media (max-width: 800px) {
		.flex-container {
			flex-direction: column!important;
		}
		.flex-revert {
			flex-direction: unset;
		}
		.only-mobile {
			display: unset;
		}
		.no-mobile {
			display: none;
		}
		.big_image img {
			width: 100%;
		}
	}
	.login_link {
		font-size: 1.1rem;
		color: #0080ff;
		font-weight: bold;
		text-decoration: none!important;
	}
</style>