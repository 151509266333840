import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import ConfirmSignup from '@/views/ConfirmSignup.vue'
import Main from '@/views/Main.vue'
import * as AccountUtils from '@/utils/accountUtils.js'
import Menu from '@/views/Menu.vue'
import Settings from '@/views/Settings.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import AdminPanel from '@/views/AdminPanel.vue'

import Landing from '@/views/Landing.vue'
import LandingMain from '@/views/LandingMain.vue'
import Terms from '@/views/Terms.vue'
import Privacy from '@/views/PrivacyPolicy.vue'

Vue.use(VueRouter)

const routes = [
{
    path: '/',
    name: 'Landing',
    component: Landing,
    redirect: { name: 'LandingMain' },
    children: [
    {
        path: '/',
        name: 'LandingMain',
        component: LandingMain
    },
    {
        path: '/terms-of-service',
        name: 'Terms',
        component: Terms
    },
    {
        path: '/policy',
        name: 'Privacy',
        component: Privacy
    },
    ]
},
{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
        guestOnly: true
    }
},

{
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
        guestOnly: true
    }
},
{
    path: '/confirmSignup/:email',
    name: 'ConfirmSignup',
    component: ConfirmSignup,
    meta: {
        guestOnly: true
    }
},
{
    path: '/changePassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
        guestOnly: true
    }
},
{
    path: '/menu',
    component: Main,
    name: 'Main',
    meta: {
        requiresAuth: true
    },
    redirect: { name: 'Menu' },
    children: [
    {
        name: 'Menu',
        path: '/menu',
        component: Menu
    },
    {
        name: 'Settings',
        path: '/settings',
        component: Settings
    },
    {
        name: 'AdminPanel',
        path: '/admin',
        component: AdminPanel,
        meta: {
            adminOnly: true
        }
    }
    ]
},
]

const router = new VueRouter({
    mode: 'history',
    routes:routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.adminOnly)) {
        if(!AccountUtils.isAdmin()) {
            next('/');
            return;
        }
    }

    if(to.matched.some(record => record.meta.requiresAuth)) {
        AccountUtils.checkSession()
        .then(function() {
            next();
        })
        .catch(function(err) {
            next({
                name: 'Login',
                params: { error: err }
            });
        });
    } else if(to.matched.some(record => record.meta.guestOnly)) {
        AccountUtils.checkSession()
        .then(function() {
            next({
                name: 'Main'
            });
        })
        .catch(function() {
            next();
        });
    } else {
        next();
    }
});

export default router
