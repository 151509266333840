<template>
	<div id="admin-panel-card" class="card">
		<div class="card-body">
			<div class="d-flex align-items-center settings-menu mb-2">
				<div class="icon_button" @click="back()">
					<i class="fa fa-angle-left"></i>
				</div>
				<h5 class="card-title mb-2">Admin panel</h5>
			</div>
			<div class="w-100 mt-4" style="display: flex;" v-if="loading">
				<div class="spinner-border mx-auto" style="width: 3rem; height: 3rem;">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div class="pt-4" v-if="users">
				<table class="table table-striped table-hover">
					<thead>
						<tr>
							<th @click="sortUsers('user_email')" >User</th>
							<th @click="sortUsers('user_type')" >User type</th>
							<th @click="sortUsers('status')" >Status</th>
							<th @click="sortUsers('created_at')" >Created at</th>
							<th @click="sortUsers('last_login')" >Last login</th>
							<th @click="sortUsers('running')" >Running</th>
							<th @click="sortUsers('credits')" >Current balance</th>
							<th @click="sortUsers('current_cost')" >Current month costs</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="user in users" v-bind:key="user.user_id" style="cursor:pointer;" @click="openUserInfo(user)">
							<th>{{user.user_email}}</th>
							<td><div class="badge" :class="userTypeClass(user)">{{userTypeName(user)}}</div></td>
							<td class="text-center">{{user['status']}}</td>
							<td class="text-center">{{user['created_at']}}</td>
							<td class="text-center">{{user['last_login']}}</td>
							<td class="text-center">{{user['running']}}</td>
							<td class="text-center" v-bind:style="moneyColor(user.credits)">{{formatMoney(user.credits)}}</td>
							<td class="text-center">
								<div class="w-100 mt-4" style="display: flex;" v-if="loadingCosts">
									<div class="spinner-border mx-auto" style="width: 3rem; height: 3rem;">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
								<div v-else-if="costs[user.user_email]">
									{{formatMoney(costs[user.user_email])}}
								</div>
								<div v-else>
									$0.00
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<UserDetailModal ref="userDetailModal"></UserDetailModal>
	</div>
</template>
<script>

	import * as AdminUtils from '@/utils/adminUtils.js'
	import UserDetailModal from '@/modals/userDetail'
	export default {
		name:'AdminPanel',
		components:{UserDetailModal},
		data() {
			return {
				users:null,
				loading:false,
				loadingCosts:false,
				costs:null,
				desc:true,
			}
		},
		mounted() {
			this.reload();
		},
		methods: {
			reload() {
				var self = this;
				return new Promise((resolve, reject) => {
					Promise.all([self.loadUsers(), self.loadCosts()])
					.then(resolve)
					.catch(reject);
				});
			},
			userTypeClass(user) {
				if(user.user_type == 'default')
					return 'badge-secondary';
				return 'badge-primary';

			},
			sortUsers(key) {

                function compare( a, b ) {
                    var a_value = a[key];
                    var b_value = b[key];
                    if(a_value === null){
                        return -1;
                    }
                    if(b_value === null){
                        return 1;
                    }
                    if (typeof a_value !== 'number'){
                        a_value = a_value.toString().toLowerCase();
                    }
                     if (typeof b_value !== 'number'){
                        b_value = b_value.toString().toLowerCase();
                    }

                    if ( a_value < b_value ){
                        return -1;
                    }
                    if ( a_value > b_value ){
                        return 1;
                    }
                    return 0;
                }

			    this.users.sort(compare);

                if(this.desc){
                    this.users.reverse();
                }
                this.desc = ! this.desc;
			},
			userTypeName(user) {
				if(user.user_type == 'default'){
					return 'Free';
				}else if(user.user_type == 'premium'){
				    return "Premium";
				}

				return "None";
			},
			back() {
				this.$router.go(-1);
			},
			loadUsers() {
				this.loading = true;
				var self = this;
				return new Promise((resolve,reject) => {
					AdminUtils.listUsers()
					.then(function(result) {
						self.loading = false;
						self.users = result
						if(self.costs !== null){
                            for(var u in self.users){
                                var user = self.users[u];
                                if(user['user_email'] in self.costs){
                                    self.users[u]['current_cost'] = self.costs[user['user_email']];
                                }else{
                                    self.users[u]['current_cost'] = 0;
                                }
                            }
				        }

						resolve();
					})
					.catch(function(err) {
						self.loading = false;

						reject(err);
					});
				})
			},
			loadCosts() {
				this.loadingCosts = true;
				var self = this;
				return new Promise((resolve,reject) => {
					var start = moment().startOf('month').format('YYYY-MM-DD');
					var end = moment().endOf('month').format('YYYY-MM-DD');
					AdminUtils.getUserCost(start, end)
					.then(function(result) {
						self.loadingCosts = false;
						self.parseCosts(result);

						resolve();
					})
					.catch(function(err) {
						self.loadingCosts = false;

						reject();
					});
				});
			},
			formatMoney(value) {
				if(value < 0)
					return '-$'+parseFloat(Math.abs(value)).toFixed(2);
				return '$'+parseFloat(value).toFixed(2);
			},
			moneyColor(value) {
				if(value < 0) {
					return {
						'color' : "#d92550"
					};
				}
				return {
					'color' : "#3ac47d"
				};

			},
			openUserInfo(user) {
				var self = this;
				this.$refs.userDetailModal.show(user, function(user) {
					return new Promise((resolve,reject) => {
						self.reload()
						.then(function() {
							for (var i = 0; i < self.users.length; i++) {
								if(self.users[i].user_id == user.user_id) {
									resolve(self.users[i]);
									return;
								}
							}
							reject();
						})
						.catch(reject);
					});
				});
			},
			parseCosts(costs) {
				this.costs = {};
				var amount = 0;
				for(const [user, summary] of Object.entries(costs)) {
					amount = 0;
					for(const [key, data] of Object.entries(summary)) {
						amount += data.total;
					}
					this.costs[user] = amount;
				}

				if(this.user !== null){
				    for(var u in this.users){
                        var user = this.users[u];
                        if(user['user_email'] in this.costs){
                            user['current_cost'] = this.costs[user['user_email']];
                        }else{
                        	user['current_cost'] = 0;
                        }
				    }
				    console.log(this.users);
				}
			}
		}
	}
</script>