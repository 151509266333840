<template>
	<div id="environment-setup-modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Setup environment</h5>
					<button type="button" class="close" @click="hide()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div v-if="user_id">
						<h5 class="card-title mb-3">Griffin environment</h5>
						<div v-for="field in fields" class="d-flex align-items-center mb-2" v-bind:key="field['key']">

                                {{field['title']}}: <input v-model="field['default']" :type="field['type']" class="ml-2 form-control">
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-success" @click="confirm()">Submit</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import * as AdminUtils from '@/utils/adminUtils.js'
	import * as GriffinUtils from '@/utils/griffinUtils.js'
	import bootbox from 'bootbox'

	export default {
		name:"environmentSetup",
		data() {
			return {
				user_id:null,
				fields:[],
				selections:{},
				data:null,
				griffinVars:null
			}
		},
		computed: {
			noNullgriffinVars() {
				var vars = {};
				for(const [key, value] of Object.entries(this.griffinVars)) {
					if(value != null)
						vars[key] = value;
				}
				return vars;
			}
		},
		mounted() {
			this.loadData();
		},
		methods: {
			show(user, vars) {
				this.user_id = user;
				var self = this;
				return new Promise((resolve,reject) => {
					self.resolve = resolve;
					self.reject = reject;
					$('#environment-setup-modal').modal("show");

					self.loadData();
				})
			},
			hide() {
				$('#environment-setup-modal').modal("hide");
			},
			loadData() {
				this.fields = [];
				this.selections = {};
				var self = this;
				blockElement('#environment-setup-modal');
				if(this.user_id != null) {
					AdminUtils.getEnvironmentScheme(this.user_id)
					.then(function(result) {
						unblockElement('#environment-setup-modal');
						self.fields = result.fields;
						self.selections = result.selections;
					})
					.catch(function(error) {
						unblockElement('#environment-setup-modal');
						bootbox.alert(error.error);
					});
				} else {
//					GriffinUtils.getEnvironmentScheme()
//					.then(function(result) {
//						unblockElement('#environment-setup-modal');
//						self.fields = result.fields;
//						self.selections = result.selections;
//					})
//					.catch(function(error) {
//						unblockElement('#environment-setup-modal');
//						bootbox.alert(error.error);
//					});
				}
			},
			confirm() {
				var self = this;
                var selections = {}
                for(var i in self.fields){
                     console.log(i);
                    selections[self.fields[i]['key']] = self.fields[i]['default'];
                }
                console.log(self.fields);
                console.log(selections);
				bootbox.confirm("Do you really want to save these settings?", function(result){
					if(result) {
						blockElement('body');

						AdminUtils.submitGriffinEnvironment(self.user_id, selections)
						.then(function() {
							unblockElement('body');
							self.hide();
							bootbox.alert("Settings saved successfully");
						})
						.catch(function(error) {
							unblockElement('body');
							bootbox.alert(error.error);
						});
					}
				});
			}
		}
	}
</script>