import * as Connector from '@/utils/connector.js'
import EventBus from '@/utils/eventBus.js'

export var token;
export var user;
export var groupList;

function setCredentials(auth, userData, groups) {
    if(auth) {
        token = auth['AccessToken'];
        sessionStorage.setItem('auth', JSON.stringify(auth));

        Connector.updateToken(token);
    }
    if(userData) {
        user = {};
        for(var i=0; i < userData.length; i++) {
            if(userData[i]['Name'] == 'email')
                user.email = userData[i]['Value'];
            else if(userData[i]['Name'] == 'name')
                user.name = userData[i]['Value'];
            else if('instance_info' in userData){
                if (userData[i]['instance_info']['type'] == 'ec2')
                    user.type = 'premium';
                else if(userData[i]['instance_info']['type'] == 'fargate')
                    user.type = 'default';
            }
        }
        sessionStorage.setItem('user_data', JSON.stringify(userData));
    }
    if(groups) {
        groupList = groups;
        sessionStorage.setItem('groups', JSON.stringify(groups));
    }
}

export function isAdmin() {
    if(groupList != null) {
        for(var i=0; i < groupList.length; i++) {
            if(groupList[i] == 'Admin')
                return true;
        }
    }
    return false;
}

export function signUp(name, email, password) {
    return new Promise((resolve,reject) => {
        Connector.post('user/signup', {
            'name' : name,
            'email' : email,
            'password' : password
        })
        .then(resolve)
        .catch(reject);
    });
}

export function confirmSignUp(email, code) {
    return new Promise((resolve,reject) => {
        Connector.post('user/confirm', {
            'email' : email,
            'code' : code
        })
        .then(resolve)
        .catch(reject);
    });
}

export function resendSignUp(email) {
    return new Promise((resolve,reject) => {
        // Auth.resendSignUp(email)
        // .then(resolve)
        // .catch(reject);
    });
}

export function signIn(email, password) {
    return new Promise((resolve,reject) => {
        Connector.post('user/login', {
            'email' : email,
            'password' : password
        })
        .then(function(result) {
            setCredentials(result['auth'],result['user'],result['groups']);
            EventBus.$emit('CREDENTIALS_UPDATED', null);
            resolve(result);
        })
        .catch(reject);
    });
}

export function checkSession() {
    if(token == null || user == null) {
        let auth = sessionStorage.getItem('auth');
        if(auth != null)
            auth = JSON.parse(auth);
        let user_data = sessionStorage.getItem('user_data');
        if(user_data != null)
            user_data = JSON.parse(user_data);
        let groups = sessionStorage.getItem('groups');
        if(groups != null)
            groups = JSON.parse(groups);
        setCredentials(auth, user_data, groups);
    }
    return new Promise((resolve,reject) => {
        if(token != null && user != null)
            resolve();
        else
            reject();
    });
}

export function signOut() {
    token = null;
    sessionStorage.removeItem('auth');
    Connector.updateToken(null);
    user = null;
    sessionStorage.removeItem('user_data');
    groupList = null;
    sessionStorage.removeItem('groups');
    EventBus.$emit('CREDENTIALS_UPDATED', null);
}

export function forgotPassword(email) {
    return new Promise((resolve,reject) => {
        Connector.post('user/forgotPassword', {
            'email' : email,
        })
        .then(resolve)
        .catch(reject);
    });
}

export function resetPassword(email, code, password) {
    return new Promise((resolve,reject) => {
        Connector.post('user/confirmForgotPassword', {
            'email' : email,
            'code' : code,
            'password' : password
        })
        .then(resolve)
        .catch(reject);
    });
}

export function editProfile(name, oldPassword='', newPassword = '') {
    return new Promise((resolve,reject) => {
        Connector.post('user/edit', {
            'name' : name,
            'oldPassword' : oldPassword,
            'newPassword' : newPassword
        })
        .then(function(result) {
            setCredentials(null, result['user']);
            EventBus.$emit('CREDENTIALS_UPDATED', null);
            resolve(result);
        })
        .catch(reject);
    });
}


export function upgrade() {
    return new Promise((resolve,reject) => {
        var callback = document.location.origin;
        Connector.get('griffin/create-checkout-session', {
            'callback' : callback+"/#/settings"
        })
        .then(resolve)
        .catch(reject);
    });
}

export function buyCredits(amount) {
    return new Promise((resolve,reject) => {
        var callback = document.location.origin;
        Connector.post('griffin/buyCredits', {
            'number_of_credits' : amount,
            'callback' : callback+"/#/settings"
        })
        .then(resolve)
        .catch(reject);
    });
}

export function setPassword(email, old_password, new_password) {
    return new Promise((resolve,reject) => {
        Connector.post('user/setPassword', {
            'email' : email,
            'old_password' : old_password,
            'new_password' : new_password
        })
        .then(function(result) {
            setCredentials(result['auth'],result['user']);
            EventBus.$emit('CREDENTIALS_UPDATED', null);
            resolve(result);
        })
        .catch(reject);
    });
}