<template>
    <div class="fill container-fluid p-0 row">
        <div class="col-md-3"></div>
        <div class="col-md-6 pt-5">
            <div class="alert alert-danger mb-2" v-if="error">
                {{ error }}
            </div>
            <div v-if="user==null">
            </div>
            <div v-else>
                <div class="mb-3">
                    <span style="font-size:1.5rem;">Hello {{ user.name }}</span>
                </div>
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</template>
<script>

import * as AccountUtils from '@/utils/accountUtils.js'
import EventBus from '@/utils/eventBus.js'

export default {
    name: 'Main',
    data() {
        return {
            user : null,
            error : null
        }
    },
    mounted() {
        var self = this;

        EventBus.$on('FORCE_LOGIN', function (data) {
            self.$router.go({ name: 'Login' });
        });

        EventBus.$on('CREDENTIALS_UPDATED', function (data) {
            self.checkUser();
        });
        EventBus.$on('MAIN_ERROR', function (data) {
            self.error = data;
        });
        this.checkUser();
    },
    methods: {
        checkUser() {
            var self = this;
            AccountUtils.checkSession()
            .then(function(result) {
                self.user = AccountUtils.user;
            });
        }
    }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>