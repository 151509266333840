<template>
    <div id="settings-card" class="card">
        <div class="card-body">
            <div class="d-flex align-items-center settings-menu mb-2">
                <div class="icon_button" @click="back()">
                    <i class="fa fa-angle-left"></i>
                </div>
                <h5 class="card-title mb-2">Settings</h5>
                <div class="w-100 d-flex flex-row-reverse">
                    <div class="settings-item" title="Profile" v-bind:class="{'active' : menuIn==2}" @click="menuIn=2">
                        <i class="fa fa-file-invoice"></i>
                        Account and Plan
                    </div>
                    <div class="settings-item" title="Billing" v-bind:class="{'active' : menuIn==1}" @click="menuIn=1">
                        <i class="fa fa-file-invoice-dollar"></i>
                        Billing
                    </div>
                    <div class="settings-item" title="Profile" v-bind:class="{'active' : menuIn==0}" @click="menuIn=0">
                        <i class="fa fa-address-card"></i>
                        Profile
                    </div>
                </div>
            </div>
            <div class="form" v-bind:hidden="menuIn != 0">
                <div class="form-group">
                    <label class="">Name</label>
                    <input v-model="name" type="name" class="form-control" v-bind:readonly="!editing">
                </div>
                <div class="form-group">
                    <label class="">Email</label>
                    <input v-model="email" type="email" class="form-control" readonly>
                </div>
                <div v-if="editing">
                    <div class="form-group">
                        <label class="">Old Password</label>
                        <input v-model="oldPassword" type="password" class="form-control" placeholder="Left empty to keep your password">
                    </div>
                    <div class="form-group">
                        <label class="">New Password</label>
                        <input v-model="newPassword" type="password" class="form-control">
                        <small class="form-text text-muted">at least 8 characters with lowercase, uppercase and special characters</small>
                    </div>
                    <div class="form-group">
                        <label class="">Re-enter new Password</label>
                        <input v-model="reNewPassword" type="password" class="form-control">
                    </div>
                </div>

                <div class="d-flex mt-2">
                    <button type="button" class="btn btn-info" @click="editing=true" v-if="!editing">Edit profile</button>
                    <button type="button" class="btn btn-success mr-2" @click="submit" v-if="editing">Save profile</button>
                    <button type="button" class="btn btn-danger" @click="editing=false" v-if="editing">Cancel</button>
                </div>
            </div>
            <div class="pt-4" v-bind:hidden="menuIn != 1">
                <userBilling :self="true"></userBilling>
            </div>
            <div v-bind:hidden="menuIn != 2">
                <div class="plan-card" v-if="userData">
                    <div class="text-center">
                        <div class="plan-card-title">Account level</div>
                        <div>{{userType}}</div>
                    </div>
                    <div class="text-center">
                        <div class="plan-card-title">Instance info</div>
                        <div class="d-flex">
                            <div class="flex-fill" v-for="(info,key) in userData.instance_info" v-bind:key="key">
                                {{info}}
                            </div>
                        </div>
                        <button type="button" class="btn btn-info mt-2" @click="changeInstance()" v-if="!isDefault">Change instance</button>
                    </div>
                    <div class="text-center">
                        <div class="plan-card-title">Current Balance</div>
                        <div><strong style="font-size: 30px" v-bind:style="balanceColor()">{{formattedBalance()}}</strong></div>
                    </div>
                </div>
                <div v-if="!isDefault">
                    <div class="card">
                        <div class="card-body" style="background-color: #eee;">
                            <div class="d-flex align-items-center justify-content-around mb-4">
                                <div style="background-color: #6c757d; width: 100%; max-width: 200px; height: 2px;"></div>
                                <h3>Need more credits?</h3>
                                <div style="background-color: #6c757d; width: 100%; max-width: 200px; height: 2px;"></div>
                            </div>
                            <div class="d-flex align-items-center justify-content-around">
                                <div class="card clickable" style="border-radius: 15px;" @click="buyCredits(5)">
                                    <div class="card-body text-center" style="border-radius: 15px;background-color:#16aaff; color: #fff;">
                                        <div style="font-size:20px">$5</div>
                                        <div style="opacity: 0.7;">credits</div>
                                    </div>
                                </div>
                                <div class="card clickable" style="border-radius: 15px;" @click="buyCredits(10)">
                                    <div class="card-body text-center" style="border-radius: 15px;background-color:#16aaff; color: #fff;">
                                        <div style="font-size:20px">$10</div>
                                        <div style="opacity: 0.7;">credits</div>
                                    </div>
                                </div>
                                <div class="card clickable" style="border-radius: 15px;" @click="buyCredits(50)">
                                    <div class="card-body text-center" style="border-radius: 15px;background-color:#16aaff; color: #fff;">
                                        <div style="font-size:20px">$50</div>
                                        <div style="opacity: 0.7;">credits</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isDefault">
                    <div class="card">
                        <div class="card-body" style="background-color: #c6dbda;">
                            <h3>Become a Premium now</h3>
                            <div class="d-flex">
                                <div class="flex-fill">
                                    <div style="font-size: 14px;">Make the most of Griffin with a Premium account</div>
                                </div>
                                <div class="flex-fill">
                                    <div class="mt-2">
                                        <li>Persistent storage</li>
                                        <li>Access to better instances</li>
                                        <li>$10 initial credits</li>
                                        <li>Tribe models</li>
                                        <li>Handle bigger networks</li>
                                    </div>
                                    <button type="button" class="btn-lg btn btn-success mt-2" @click="upgrade">Upgrade now for $10</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <changeInstance ref="changeInstance"/>
    </div>
</template>
<script>

    import * as AccountUtils from '@/utils/accountUtils.js'
    import * as GriffinUtils from '@/utils/griffinUtils.js'
    import EventBus from '@/utils/eventBus.js'
    import userBilling from '@/components/userBilling'

    import bootbox from 'bootbox'
    import changeInstance from '@/modals/changeInstance'

    export default {
        name: 'Settings',
        components:{userBilling,changeInstance},
        data() {
            return {
                publishableKey:'pk_test_51KbT1ZECJPKXs2LCrZ0c9CmPWBaAldZmlQZDh5YK7AYYCH0wOrbhtK7k50OsxqOsWsYWmo6Zj4Gk3N0Ia97s6Qkz00dYzeNPPE',
                lineItems:[{'price':'price_1KbqTUECJPKXs2LCc3uZYbpE','quantity':5}],
                successURL:'https://localhost:8080/#/ok',
                cancelURL:'https://localhost:8080/#/error',
                menuIn : 0,
                editing: false,
                name: '',
                email: '',
                userType: 'default',
                oldPassword: '',
                newPassword: '',
                reNewPassword: '',
                userData:null
            }
        },
        computed: {
            isDefault: function() {
                if(this.userType) {
                    return this.userType == 'Free';
                }
                return true;
            },
            credits() {
                if(this.userData) {
                    return parseFloat(this.userData.credits);

                }
                return 0;
            }
        },
        watch: {
            editing: function() {
                this.name = AccountUtils.user.name;
                this.oldPassword = '';
                this.newPassword = '';
                this.reNewPassword = '';
            },
        },
        mounted() {
            this.loadInformation();

            var query = this.$route.query;
            this.checkQuery(query);
        },
        methods: {
            checkQuery(query) {
                if(Object.keys(query).length > 0) {
                    if(query['credit'] != null) {
                        if(query['credit'] == 1) {
                            bootbox.alert("Credit added successfully");
                        } else {
                            bootbox.alert("There was an error adding credits.");
                        }
                    } else if(query['premium'] != null) {
                        if(query['premium'] == 1) {
                            bootbox.alert("Preimum added successfully");
                        } else {
                            bootbox.alert("There was an error adding premium.");
                        }
                    }
                    this.$router.push(this.$route.path);
                }
            },
            loadInformation() {
                this.name = AccountUtils.user.name;
                this.email = AccountUtils.user.email;
                var self = this;
                GriffinUtils.getUserData()
                .then(function(result) {
                    self.userData = result;
                    if (result['instance_info']['type'] == 'ec2')
                        self.userType = 'Premium';
                    else
                        self.userType = 'Free';
                })
                .catch(function(err) {
                });
            },
            balanceColor() {
                if(this.credits < 0) {
                    return {
                        'color' : "#d92550"
                    };
                }
                return {
                    'color' : "#3ac47d"
                };

            },
            formattedBalance() {
                if(this.credits >= 0)
                    return '$'+parseFloat(this.credits).toFixed(2);
                else
                    return '-$'+parseFloat(Math.abs(this.credits)).toFixed(2);
            },
            back() {
                 this.$router.push({ name: 'Menu'});
            },
            submit() {
                var self = this;

                if(this.oldPassword.length > 0) {
                    if(this.newPassword.length == 0) {
                        EventBus.$emit('MAIN_ERROR', "Please, enter your new password");
                        return;
                    }
                    if(this.reNewPassword.length == 0) {
                        EventBus.$emit('MAIN_ERROR', "Please, re-enter your new password");
                        return;
                    }
                    if(this.newPassword != this.reNewPassword) {
                        EventBus.$emit('MAIN_ERROR', "The new password does not match");
                        return;
                    }
                }

                EventBus.$emit('MAIN_ERROR', null);

                blockElement('#settings-card');
                AccountUtils.editProfile(this.name, this.oldPassword, this.newPassword)
                .then(function(result) {
                    unblockElement('#settings-card');
                    self.loadInformation();
                    self.editing = false;
                })
                .catch(function(error) {
                    unblockElement('#settings-card');
                    EventBus.$emit('MAIN_ERROR', error.error);
                });
            },
            upgrade(){
                blockElement('#settings-card');
                AccountUtils.upgrade()
                .then(function(url) {
                    unblockElement('#settings-card');
                    window.location.replace(url);
                }).catch(function(err) {
                    unblockElement('#settings-card');
                    bootbox.alert(err);
                });
            },
            buyCredits(amount){
                blockElement('#settings-card');
                AccountUtils.buyCredits(amount)
                .then(function(url) {
                    unblockElement('#settings-card');
                    window.location.replace(url);
                }).catch(function(err) {
                    unblockElement('#settings-card');
                    bootbox.alert(err);
                });

            },
            changeInstance() {
                var self = this;
                this.$refs.changeInstance.show()
                .then(function(selections) {
                    self.userData.instance_info = selections;
                });
            }
        }
    }
</script>
<style>
    .plan-card {
        border-color: #eee;
        border-width: 2px;
        border-style: solid;
        border-radius: 5px;
        margin-bottom: 1rem;
        display: flex;
    }
    .plan-card>div {
        padding: 1.2rem;
        flex: 1 1 auto !important;
    }
    .plan-card>div:not(:first-child) {
        border-left-color: #eee;
        border-left-style: solid;
        border-left-width: 2px;
    }
    .plan-card-title {
        opacity: 0.5;
        font-size: 13px;
    }
</style>