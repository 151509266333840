<template>
	<div>
		<div class="top_area">
		</div>
		<div id="Policy" class="container">
			<h2>Privacy Policy</h2>
			<p>
				Galaxyadvisors AG, Laurenzenvorstadt 69, CH-5000 Aarau, Switzerland (“<strong><em>us</em></strong>,” “<strong><em>our</em></strong>”, “<strong><em>we</em></strong>,”), is the owner of the Griffin website (this
				“<strong><em>Site</em></strong>”) and the platform and the Griffin applications and services offered by us, including all beta services (collectively, the “<strong><em>Services</em></strong>”). Your access to and use of the Site and the
				Services are subject to the terms of this Privacy Policy. <strong>By using the Services, you agree to the practices and policies outlined in this Privacy Policy and you hereby consent to our tracking, collection, use, and sharing of your
				information as described in this Privacy Policy.</strong>
			</p>
			<h6>1. Information We Collect</h6>
			<p>
				(a) <em>Information You Give to Us.</em> When you access the Site and/or the Services, we may ask you to voluntarily provide us certain information that personally identifies (or could be used to personally identify) you (“<strong><em>Personal
				Data</em></strong>”). Personal Data may include, without limitation, some of the following categories of information: (1) contact and profile data (such as your name, address, zip code, e­mail address, profile picture, and phone number);
				(2) your billing information; (3) questions, comments, and other information you provide on our private or public internet-based community sites and user forums, and (4) other information that you voluntarily choose to provide to us, including
				without limitation, unique identifiers such as passwords, and personal information that you send to us. You may still access and use some of the Services if you choose not to provide us with any Personal Data, but the features of the Services
				that require your Personal Data will not be accessible to you. We may also collect additional information, which may be Personal Data, as otherwise described to you at the point of collection or pursuant to your consent and share this
				information with our business partners in order to provide the Services to you and as otherwise set forth herein.<br>(b) <em>Information Gathered from Your Use of the Services</em>. We automatically collect certain data when you use the Site
				and/or the Services, such as (1) IP address; (2) domain server; (3) type of device(s) used to access the Services; (4) web browser(s) used to access the Services; (5) referring webpage or other source through which you accessed the Services;
				(6) geolocation information; (7) beta use information; (8) session information generated and stored on your computer systems; and (9) other statistics and information associated with the interaction between your browser or device and the
				Services (collectively “<strong><em>Traffic Data</em></strong>”). When you use the Services, we also collect meta-data from your email accounts and your usage thereof, including, without limitation, the sender, recipient(s), date, and subject
				of the email (collectively, “<strong><em>Email Meta-Data</em></strong>”), provided, however, that we do not access the body of the email message or the content of any attachments in providing the Services. Depending on applicable law, some
				Traffic Data may be Personal Data and some Email Meta-Data may contain Personal Data.
			</p>
			<h6>2. How We Collect Information</h6>
			<p>
				We collect information, including Personal Data and Traffic Data, when you register on the Site, use and interact with the Services, fill out a form, use live chat, surf the Site, enter information on our Site, or provide us with feedback on
				our Services, as well as through Cookies, Web Beacons, website analytics services and other tracking technology (collectively, “<strong><em>Tracking Tools</em></strong>”) as described below. We collect Email Meta-Data when you use the Services.
				We also may collect information about you from third party sources.
			</p>
			<h6>3. Tracking Tools</h6>
			<p>
				(a) <em>Cookies</em>. “Cookies” are small computer files transferred to your computing device that contain information such as user ID, user preferences, lists of pages visited and activities conducted while using the Services. We use Cookies
				to help us improve the Services by tracking your navigation habits, storing your authentication status so you do not have to re­enter your credentials each time you use the Services, customizing your experience with the Services and for
				analytics and fraud prevention. You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some portions of our Services. Please note that we
				may continue to collect information about your use of the Services even after you have opted out of allowing Cookies. For more information on cookies, visit http://www.allaboutcookies.org.<br>(b) <em>Web Beacons</em>: “Web Beacons” (a.k.a.
				clear GIFs or pixel tags) are tiny graphic image files imbedded in a web page or email that may be used to collect anonymous information about your use of our Services, the websites of selected advertisers and the emails, special promotions or
				newsletters that we send you. The information collected by Web Beacons allows us to monitor how many people are using the Services or opening our emails, and for what purpose.<br>(c) <em>Website Analytics</em>: We may use third-party website
				analytics services in connection with the Services, including, for example, to record mouse clicks, mouse movements, scrolling activity and text that you type into the Site. These website analytics services generally do not collect Personal
				Data unless you voluntarily provide it and generally do not track your browsing habits across websites which do not use their services. We use the information collected from these services to help make the Services easier to use and as
				otherwise set forth herein.
			</p>
			<h6>4. Information Provided by or on Behalf of Children</h6>
			<p>
				The Services are not intended for use by children under sixteen (16) years of age and children under age 16 are prohibited from using the Services. We do not knowingly collect any information from children under 16. By accessing, using and/or
				submitting information to or through the Services, you represent that you are at least 16 years of age. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, please contact us
				immediately. If we learn that we have received or inadvertently gathered any information from a child under age 16, we will use reasonable efforts to delete that information from our records as soon as reasonably practicable.
			</p>
			<h6>5. Use of Data</h6>
			<p>
				We use your information, including Personal Data, to provide the Services and related support to you and to help improve them, including to contact you when necessary or requested and to prevent, detect and investigate security breaches and
				potentially illegal or prohibited activities, as well as for the other purposes specified in this Privacy Policy and as described in our Terms of Use or any other agreement between you and us regarding the Site or the Services. We may use
				information that is not Personal Data (including Personal Data that has been de­identified and/or aggregated, but excluding Email Meta-Data) for marketing, advertising, product utilization analysis, or any other uses in our sole discretion.
			</p>
			<p>

				App’s use of information received from Gmail APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional-requirements-for-specific-api-scopes">Google's Limited Use Requirements</a>.

			</p>

			<h6>6. Disclosure of Data</h6>
			<p>
				We may disclose and/or transfer certain information that we collect from you (which may include Personal Data, Traffic Data, and/or Email Meta-Data) as follows:<br>(a) <em>To Service Providers</em>. We may engage third party companies and
				individuals to perform core services related to the operation of our Services (such as hosting, billing, fulfillment, data storage, and database management services). Notwithstanding the foregoing, we will only disclose and/or transfer Email
				Meta-Data to our third-party hosting providers whom we engage to store such data, and only to the extent necessary for us to provide the Services, or to other third-parties upon your request. We may also disclose your information (excluding
				Email Meta-Data) to third parties who perform website analytics on our behalf. These third parties may only use your data as directed by us and in a manner consistent with this Privacy Policy and are prohibited from using or disclosing your
				data for any other purpose.<br>(b) <em>Business Transfers</em>. We may sell, transfer or otherwise share some or all of our business or assets, which may include your Personal Data, to another company in connection with a merger, sale,
				acquisition or other change of ownership or control by or of us (whether in whole or in part), in which case we will use reasonable efforts to require the recipient to honor this Privacy Policy and notify you via email and will post a notice on
				the Site alerting you to the business transition prior to transferring your Personal Data and before your information becomes subject to materially different privacy and security policies and practices.<br>(c) <em>Compliance with Law</em>. We
				may disclose such information if we determine in good faith that such disclosure is needed to: (1) comply with applicable law, regulation, court order or other legal process; (2) protect our rights, property or safety or that of another party;
				(3) enforce other agreements with you; or (4) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.
			</p>
			<h6>7. Public Information</h6>
			<p>
				Any information that you post in chat rooms or message boards on the Site or reveal in a review of the Services to us or on the Site or on any other website is public information and may be shared or used by us for any reason in our sole
				discretion.
			</p>
			<h6>8. Storage and Security of Information</h6>
			<p>
				The security of your Personal Data is important to us. We use a variety of industry-standard technical, contractual, administrative and physical security measures and procedures to protect the Personal Data in our possession or control from
				unauthorized access, use, and disclosure, both during transmission and in storage. However, no security safeguards are 100% secure and we cannot guarantee its absolute security. If we become aware that your Personal Data has been disclosed in a
				manner not in accordance with this Privacy Policy, we will use reasonable efforts to notify you of the nature and extent of the disclosure (to the extent we know that information) as soon as reasonably possible and as permitted or required by
				applicable law. If you have questions about our security measures in place, please contact us as set forth below.
			</p>
			<h6>9. Controlling Your Personal Data</h6>
			<p>
				If you are a registered user of the Services, you may modify certain portions of your Personal Data directly within your account settings where possible, or by contacting us to make the required changes. If you close your account or the
				agreement by which you purchase the Services expires or otherwise terminates, we will delete your account and all Email Meta-Data in your account without undue delay and will purge your profile data from our user database. Please note, however,
				that we reserve the right to retain information from closed accounts, including to comply with law, prevent fraud, resolve disputes, enforce any agreement between you and us, and take other actions permitted by law. You must promptly notify us
				if you become aware that any of your account data is lost, stolen or used without permission.
			</p>
			<h6>10. Your Sharing Options</h6>
			<p>
				If you do not want to receive special offers, marketing communications, advertisements, newsletters or other promotional communications (“<strong><em>Marketing Communications</em></strong>“) from us, you may opt-out by clicking the
				“Unsubscribe” link at the bottom of all marketing emails. Please note that once we receive your request, it may take up to ten (10) business days for such opt-out to become effective. Also note that if you opt-out of our Marketing
				Communications, we may still email or communicate with you from time to time if we need to provide you with information or request information from you with respect to a transaction initiated by you, or for other legitimate non-marketing
				reasons. <em>The Services will provide automatic Griffin analysis reporting emails which are fundamental to delivery of the Services. This may be discontinued solely through discontinuation of your use of the Services.</em>
			</p>
			<h6>11. Business Transitions</h6>
			<p>
				As we continue to develop our business, we may sell or buy companies, subsidiaries, or business units. In such transactions, customer information, including Personal Data, is generally considered an asset, but remains subject to the terms and
				conditions of any pre-existing Privacy Policy (unless, of course, a customer specifically requests otherwise in writing). In the event that we or substantially all of our assets are acquired, or a change in control of the majority ownership of
				us occurs, Personal Data may be one of the transferred assets.
			</p>
			<h6>12. Links to Other Websites</h6>
			<p>
				The Services may contain links to third-party websites. A link to a third-party website does not mean that we endorse that website, the quality or accuracy of information presented on the website, or the persons or entities associated with the
				website. If you decide to visit a third-party website, you are subject to the privacy policy of the third-party website, as applicable, and we are not responsible for the policies and practices of the third-party website. This Privacy Policy
				does not apply to any other website or digital service that you may be able to access through the Services, which may have data collection, storage and use practices and policies that may materially differ from this Privacy Policy.
			</p>
			<h6>13. Updates and Changes to Privacy Policy</h6>
			<p>
				The effective date of this Privacy Policy is set forth at the top of this webpage. We may update this Privacy Policy from time to time. We will notify you of any material change by posting the new Privacy Policy on this webpage. You are
				advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page unless otherwise indicated. Your continued use of the Services after the effective date
				constitutes your acceptance of the amended Privacy Policy. The amended Privacy Policy supersedes all previous versions. <strong>If you do not agree to future changes to this Privacy Policy, you must stop using the Services after the effective
				date of such changes.</strong>
			</p>
			<h6>14. Transfers of Personal Data</h6>
			<p>
				We are headquartered in Switzerland and have service providers in other countries, and your Personal Data may be transferred to Switzerland or other locations outside of your state, province, country, or other governmental jurisdiction where
				privacy laws may not be as protective as those in your jurisdiction. European Union users should read the important information provided here about transfer of personal information outside of the European Economic Area. Your consent to this
				Privacy Policy followed by your submission of such information represents your agreement to that transfer.
			</p>
			<h6>15. Contact Us</h6>
			<blockquote>If you have any comments, concerns or questions about this Privacy Policy, please contact us at: Galaxyadvisors AG, Laurenzenvorstadt 69, CH-5000 Aarau, Switzerland, or by email to: info@galaxyadvisors.com.</blockquote>
			<h6>Additional Information for European Union Users</h6>
			<p>
				Legal basis for processing. We only use your personal data as permitted by law. We are required to inform you of the legal basis of our processing of your personal data, which are described in the "Use of Data" section above. If you have
				questions about the legal basis of how we process your Personal Data, contact us using the contact information provided above.
			</p>
			<p>
				Use for new purposes. We may use your Personal Data for reasons not described in this Privacy Policy where permitted by law and the reason is compatible with the purpose for which we collected it. If we need to use your Personal Data for an
				unrelated purpose, we will notify you and explain the applicable legal basis.
			</p>
			<p>
				Retention. We will only retain your Personal Data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate
				retention period for Personal Data, we consider the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we process your personal data
				and whether we can achieve those purposes through other means, and the applicable legal requirements. We keep basic information about our users for the duration of the agreement. In some circumstances we may anonymize your Personal Data (so
				that it can no longer be associated with you) in which case we may use this information indefinitely without further notice to you.
			</p>
			<p>
				Your Rights. European data protection laws give you certain rights regarding your Personal Data. You may ask us to take the following actions in relation to your Personal Data that we hold:
			</p>
			<p>
				* <strong>Opt-out</strong>. Stop sending you direct marketing communications. You may continue to receive service-related and other non-marketing emails.
			</p>
			<p>
				* <strong>Access</strong>. Provide you with information about our processing of your Personal Data and give you access to your Personal Data.
			</p>
			<p>
				* <strong>Correct</strong>. Update or correct inaccuracies in your Personal Data.
			</p>
			<p>
				* <strong>Delete</strong>. Delete your Personal Data.
			</p>
			<p>
				* <strong>Transfer</strong>. Transfer a machine-readable copy of your Personal Data to you or a third party of your choice.
			</p>
			<p>
				* <strong>Restrict</strong>. Restrict the processing of your Personal Data.
			</p>
			<p>
				* <strong>Object</strong>. Object to our reliance on our legitimate interests as the basis of our processing of your Personal Data that impacts your rights.
			</p>
			<p>
				You can submit these requests by email to info@galaxyadvisors.com or our postal address provided above. We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or
				permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your Personal Data or response to your requests regarding your Personal
				Data, you may contact us as described above or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator here:
				http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm.
			</p>
			<h6>Subprocessors</h6>
			<p>
				We engage certain third parties ("<strong><em>Subprocessors</em></strong>") in order to assist us in connection with the Services, some of whom may have access to your Personal Data in conjunction with such processing. Please contact us for a
				list of our current Subprocessors.
			</p>
			<h6>Cross-Border Data Transfer</h6>
			<p>
				Whenever we transfer your Personal Data out of the EEA to countries not deemed by the European Commission to provide an adequate level of personal information protection, the transfer, where required by EU data protection legislation, will be
				based on the Standard Contractual Clauses approved by the European Commission which impose data protection obligations on the parties to the transfer.
			</p>
			<p>
				Please contact us for a copy of our Data Processing Addendum or if you want further information on the specific mechanism used by us when transferring your personal information out of the EEA.
			</p>
		</div>
	</div>
</template>
<script>
	export default {
		name:"Privacy",
		data() {
			return {

			}
		},
		methods: {
			
		}
	}
</script>