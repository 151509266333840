
import * as AccountUtils from '@/utils/accountUtils.js'
import EventBus from '@/utils/eventBus.js'

var AUX_URL =  process.env.VUE_APP_BASE_URL

if (typeof AUX_URL == 'undefined') {
    // the variable is defined
    AUX_URL = window.location.protocol + "//" + window.location.host + "/api/";
}
export const BASE_URL = AUX_URL

const axios = require('axios')
const instance = axios.create({
    headers: {
        "Content-Type" : "application/json",
    },
    timeout: 20*1000
});

export function updateToken(token) {
    if(token != null) {
        instance.defaults.headers.common['Authorization'] = token;
    } else {
        delete instance.defaults.headers.common['Authorization'];
    }
}

export function get($route, $params = null) {
    return new Promise( (resolve, reject) => {
        let route = BASE_URL+$route;
        instance.get(route,{ params: $params })
        .then(function(result) {
            resolve(result.data);
        })
        .catch(function(err) {
            checkError(err, reject);
        });
    });
}

export function post($route, $params) {
    return new Promise( (resolve, reject) => {
        let route = BASE_URL+$route;
        instance.post(route, $params)
        .then(function(result) {
            resolve(result.data);
        })
        .catch(function(err) {
            checkError(err, reject);
        });
    });
}

function checkError(err, reject) {
    try {
        var data = err.response;
        if(data.status == 401) {
            AccountUtils.signOut();
            EventBus.$emit('FORCE_LOGIN', null);
        }
        reject(err.response.data)
    } catch (e) {
        reject({
            'error' : e
        });
    }
}