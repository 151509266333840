<template>
	<div>
		<div class="top_area">
			<div class="container">
				<div class="flex-container">
					<div class="flex_even">
						<div class="section_title">
							Analyze your data and check insights about your business
						</div>
						<p class="section_paragraph">
							Use <strong>Griffin</strong> and it's powerfull data analytics software to know more about your business, communication and social media
						</p>
						<div class="start_button mt-5 mb-4" v-on:click="signIn">
							<div class="d-flex">
								<div style="margin-right: 2rem;" v-if="!logged">
									Register now and start using
									<br><strong>Griffin</strong>
								</div>
								<div style="margin-right: 2rem;" v-else>
									Enter on Griffin as
									<br><strong>{{user.name}}</strong>
								</div>
								<em class="fa fa-arrow-right" style="font-size: 20px;margin-top: 4px;"></em>
							</div>
						</div>
					</div>
					<div class="flex_even">
						<img class="ml-auto mr-auto w-100" src="../assets/images/ss1.png" style="box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; border-radius: 10px;">
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="flex-container justify-content-between align-items-center">
				<div class="card_item background_orange" style="width: 300px">
					<div class="card_item_title">Data processing</div>
					<div class="card_item_info">Get data from the main data sources across the internet</div>
				</div>
				<em class="fa fa-arrow-right no-mobile" style="font-size: 40px"></em>
				<em class="fa fa-arrow-down only-mobile my-2" style="font-size: 40px"></em>
				<div class="card_item background_blue" style="width: 300px">
					<div class="card_item_title">Data analytics</div>
					<div class="card_item_info">Analyze by yourself with easy to learn tools</div>
				</div>
				<em class="fa fa-arrow-right no-mobile" style="font-size: 40px"></em>
				<em class="fa fa-arrow-down only-mobile my-2" style="font-size: 40px"></em>
				<div class="card_item background_green" style="width: 300px">
					<div class="card_item_title">Data visualization</div>
					<div class="card_item_info">Visualize your data and analysis at anytime, anywhere</div>
				</div>
			</div>
		</div>
		<div class="container section mt-5">
			<div class="flex-container">
				<div class="flex_even">
					<div class="section_title">
						Get data from the main data sources across the internet
					</div>
					<p class="section_paragraph">
						Fetch data from your favorite services, all inside <strong>Griffin</strong>
					</p>
				</div>
				<div class="row flex_even justify-content-around align-items-stretch mt-5">
					<div class="col-6 mb-2">
						<div class="card back-theme">
							<div class="card-body text-center">
								<em class="fab fa-twitter" style="font-size: 40px;margin-bottom: 20px;"></em>
								<br><strong>Twitter</strong>
							</div>
						</div>
					</div>
					<div class="col-6 mb-2">
						<div class="card back-theme">
							<div class="card-body text-center">
								<em class="fab fa-google" style="font-size: 40px;margin-bottom: 20px"></em>
								<br><strong>Google</strong>
							</div>
						</div>
					</div>
					<div class="col-6 mb-2">
						<div class="card back-theme">
							<div class="card-body text-center">
								<em class="fab fa-microsoft" style="font-size: 40px;margin-bottom: 20px"></em>
								<br><strong>Microsoft</strong>
							</div>
						</div>
					</div>
					<div class="col-6 mb-2">
						<div class="card back-theme">
							<div class="card-body text-center">
								<em class="fab fa-reddit" style="font-size: 40px;margin-bottom: 20px"></em>
								<br><strong>Reddit</strong>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="container section">
			<div class="flex-container flex-revert">
				<div class="flex_even">
					<div class="section_title">
						Analyze your data using Phoenix
						<img src="../assets/images/phoenix_logo.png" style=" width: 30px;">
					</div>
					<p class="section_paragraph">
						<strong>Phoenix</strong> is a powerful online SNA application, allowing to process and analyse networks in different ways
					</p>
				</div>
				<div class="flex_even d-flex flex-column align-items-center">
					<div style="height: 223px;display: flex;">
						<img id="mok_image_2" class="mok_image" src="../assets/images/ss2.png" v-on:click="toggleBigImage()">
						<img id="mok_image_3" class="mok_image" src="../assets/images/ss3.png" hidden="true" v-on:click="toggleBigImage()">
						<img id="mok_image_4" class="mok_image" src="../assets/images/ss4.png" hidden="true" v-on:click="toggleBigImage()">
						<img id="mok_image_5" class="mok_image" src="../assets/images/ss5.png" hidden="true" v-on:click="toggleBigImage()">
					</div>
					<div class="d-flex justify-content-around mt-4 w-75">
						<div class="chip_button active btn_2" v-on:click="changePhoto(2)"></div>
						<div class="chip_button btn_3" v-on:click="changePhoto(3)"></div>
						<div class="chip_button btn_4" v-on:click="changePhoto(4)"></div>
						<div class="chip_button btn_5" v-on:click="changePhoto(5)"></div>
					</div>
				</div>
			</div>
			<div class="big_image" v-bind:hidden="showBigImage" v-on:click="toggleBigImage()">
				<img id="mok_big_image" src="../assets/images/ss2.png">
				<div class="d-flex justify-content-around mt-4 ml-auto mr-auto" style="width: fit-content">
					<div class="chip_button active btn_2 mr-4" v-on:click.stop="changePhoto(2)"></div>
					<div class="chip_button btn_3 mr-4" v-on:click.stop="changePhoto(3)"></div>
					<div class="chip_button btn_4 mr-4" v-on:click.stop="changePhoto(4)"></div>
					<div class="chip_button btn_5 mr-4" v-on:click.stop="changePhoto(5)"></div>
				</div>
			</div>
		</div>
		<div class="container section">
			<div class="flex-container">
				<div class="flex_even">
					<div class="section_title">
						Security and privacy
					</div>
					<p class="section_paragraph">
						<strong>Griffin</strong> was build for security from day one.
					</p>
				</div>
				<div class="flex_even d-flex flex-column">
					<div class="card back-theme mb-4">
						<div class="card-body d-flex align-items-center">
							<em class="fas fa-lock" style="padding-right:20px"></em>Safe infrasctructure
						</div>
					</div>
					<div class="card back-theme mb-4">
						<div class="card-body d-flex align-items-center">
							<em class="fas fa-lock" style="padding-right:20px"></em>Separated instance for each user
						</div>
					</div>
					<div class="card back-theme mb-4">
						<div class="card-body d-flex align-items-center">
							<em class="fas fa-lock" style="padding-right:20px"></em>No sharing of information between instances
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import * as AccountUtils from '@/utils/accountUtils.js'
	
	export default {
		name:"LandingMain",
		data() {
			return {
				currentImage:2,
				showBigImage:true,
				logged:false,
				user:null

			}
		},
		mounted() {
			var self = this;
			AccountUtils.checkSession()
			.then(function() {
				self.logged = true;
				self.user = AccountUtils.user;
			})
			.catch(function(err) {
				self.logged = false;
			});
		},
		methods: {
			signIn() {
				let routeData = this.$router.resolve({name: 'Login'});
				window.open(routeData.href, '_blank');
			},
			changePhoto(id) {
				$(".mok_image").attr("hidden", true);
				$('.chip_button').removeClass('active');
				$('.btn_'+id).addClass('active');
				$("#mok_image_"+id).attr("hidden", false);
				this.currentImage = id;
				this.changeBigImage();
			},
			toggleBigImage() {
				this.showBigImage = !this.showBigImage;
				if(this.showBigImage) {
					this.changeBigImage();
				}
			},
			changeBigImage() {
				var src = $("#mok_image_"+this.currentImage).attr("src");
				$("#mok_big_image").prop("src", src);
			}
		}
	}
</script>