<template>
	<div>
		<div v-if="userData">
			<div class="mb-4" v-if="instanceInfo">
				<h5 class="card-title mb-3">Instance info</h5>
				<div class="d-flex mb-2">
					<div class="mr-4"> 
						<div class="cost-total-title">Current Balance:</div> 
						<div class="balance-value" v-bind:style="balanceColor()">{{formattedBalance()}}</div>
					</div>
					<div class="d-flex mt-auto flex-wrap">
						<div class="mr-4" v-for="(info,key) in instanceInfo" v-bind:key="key">
							<div class="cost-total-title">{{key}}:</div>
							{{info}}
						</div>
					</div>
				</div>
				<div class="d-flex align-items-center">
					<div class="badge mr-2" :class="userTypeClass()">{{userTypeName()}}</div>
					<button type="button" v-if="admin" class="btn btn-outline-info mr-2" @click="changeEnvironment()">Setup environment</button>
					<button type="button" v-if="admin && isPremium" class="btn btn-outline-info mr-2" @click="changeInstance()"><i class="fa fa-desktop mr-2"></i>Change instance type</button>
					<button type="button" v-if="admin" class="btn btn-outline-info mr-2" @click="charge()"><i class="fa fa-dollar-sign mr-2"></i>Add credits</button>
					<button type="button" v-if="admin && isPremium" class="btn btn-outline-danger mr-2" @click="downgradeUser()"><i class="fa fa-arrow-down mr-2"></i>Downgrade user</button>
					<button type="button" v-else-if="admin" class="btn btn-outline-success mr-2" @click="upgradeUser()"><i class="fa fa-arrow-up mr-2"></i>Upgrade user</button>
				</div>
				<hr>
			</div>
			<div class="mb-4 d-flex">
				<div>
					<div class="cost-total-title">Billing period</div> 
					<div class="d-flex align-items-center">
						<DatePicker v-model="month" value-type="YYYY-MM-DD" format="MMM, YYYY" type="month" @input="loadCost()"></DatePicker>
						<button type="button" class="btn btn-success ml-2" @click="loadCost()">Refresh</button>
						<button type="button" class="btn btn-info ml-2" @click="currentMonth()">Current Month</button>
						<button type="button" class="btn btn-info ml-2" @click="lastMonth()">Last Month</button>
					</div>
				</div>
			</div>
			<div class="w-100 mt-4" style="display: flex;" v-if="loading">
				<div class="spinner-border mx-auto" style="width: 3rem; height: 3rem;">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div v-else-if="cost">
				<h5 class="card-title mb-3">Summary</h5>
				<div style="background-color: #97c1a9;" class="card cost-card flex-fill mb-2">
					<div class="card-body">
						<div class="cost-title">Total Cost</div>
						<span class="cost-amount">{{formattedTotal()}}</span>
					</div>
				</div>
				<div class="d-flex justify-content-around mb-5 flex-wrap">
					<div v-for="(summary,title) in cost.summary" v-bind:style="costCardColor(title)" class="card cost-card flex-fill mr-2 mb-2" v-bind:key="title">
						<div class="card-body">
							<div class="cost-title">{{title}}</div>
							<span class="cost-amount">{{formattedUsage(summary)}}</span> <span class="cost-unit">{{summary.unit}}</span>
						</div>
					</div>
				</div>
				<canvas id="time_chart" class="w-100" height="400"></canvas>
			</div>
			<div v-else class="text-center">
				<label>
					No billing found for the selected period
				</label>
			</div>
		</div>
		<environmentSetupModal ref="environmentSetup"></environmentSetupModal>
		<changeInstance ref="changeInstance"></changeInstance>
	</div>
</template>
<script>

	import * as AdminUtils from '@/utils/adminUtils.js'
	import DatePicker from 'vue2-datepicker'
	import Chart from 'chart.js/auto'
	import * as GriffinUtils from '@/utils/griffinUtils.js'
	import environmentSetupModal from '@/modals/environmentSetup'
	import changeInstance from '@/modals/changeInstance'
	import bootbox from 'bootbox'

	export default {
		name: "userBilling",
		props: {
			user:null,
			self: {
				type:Boolean,
				default:false
			},
			admin: {
				type:Boolean,
				default:false
			},
			reloadCallback: {
				type:Function,
				default:null
			}
		},
		components:{DatePicker,environmentSetupModal,changeInstance},
		data() {
			return {
				userData:null,
				month:moment().startOf('month').format('YYYY-MM-DD'),
				cost:null,
				colors : [
				'#a7bed3',
				'#cbaacb',
				'#ffccb6',
				'#f3b0c3',
				'#c6dbda',
				'#8fcaca',
				'#97c1a9'
				],
				colorDataMap: {},
				loading:false,
			}
		},
		computed: {
			instanceInfo() {
				var info = null;
				info = this.userData['instance_info']


				return info;
			},
			griffinVars() {
				var vars = null;
				vars = this.userData['griffin_environment_variables']

				return vars;
			},
			credits() {
				if(this.userData) {
					if(!this.self)
						return parseFloat(this.userData.credits);

				}
				return 0;
			},
			isPremium() {
				if(this.self) {
					return this.userData.user_type != 'default';
				} else {
					return this.user.user_type != 'default';
				}
			},
			userId() {
				if(this.self) {
					if(this.userData)
						return this.userData.user_id.S;
				} else {
					if(this.user)
						return this.user.user_id;
				}
				return null;
			}
		},
		watch: {
			cost: function() {
				this.colorDataMap = {};
				if(this.cost != null && this.cost.summary != null) {
					var i = 0;
					for(const [key, values] of Object.entries(this.cost.summary)) {
						this.colorDataMap[key] = this.colors[i%this.colors.length];
						i++;
					}
				}
			},
			user: function() {
				this.cost = null;
				this.month = moment().startOf('month').format('YYYY-MM-DD');
				this.userData = this.user;
				if(this.user != null) {
					this.loadCost();
				}
			}
		},
		mounted() {
			if(this.self) {
				this.userData = {};
				this.loadCost();
			}
		},
		methods: {
			userTypeClass() {
				if(!this.isPremium)
					return 'badge-secondary';
				return 'badge-primary';

			},
			userTypeName(user) {
				if(!this.isPremium)
					return 'Free';
				return "Preimum";
			},
			balanceColor() {
				if(this.credits < 0) {
					return {
						'color' : "#d92550"
					};
				}
				return {
					'color' : "#3ac47d"
				};

			},
			formattedBalance() {
				if(this.credits >= 0)
					return '$'+parseFloat(this.credits).toFixed(2);
				else
					return '-$'+parseFloat(Math.abs(this.credits)).toFixed(2);
			},
			formattedTotal() {
				if(this.cost && this.cost.total != null)
					return '$'+this.cost.total.toFixed(2);
				return '$0.00';
			},
			formattedUsage(summary) {
				return summary.usage.toFixed(2);
			},
			costCardColor(summary) {
				return {
					'background-color' : this.colorDataMap[summary]
				};
			},
			currentMonth() {
				this.month = moment().startOf('month').format('YYYY-MM-DD');
				this.loadCost();
			},
			lastMonth() {
				this.month = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				this.loadCost();
			},
			loadCost() {
				this.cost = null;
				var self = this;
				var start = moment(this.month).startOf('month').format('YYYY-MM-DD');
				var end = moment(this.month).endOf('month').format('YYYY-MM-DD');
				this.loading = true;
				if(this.self) {
					GriffinUtils.user_cost(start, end)
					.then(function(result) {
						self.cost = result;
						self.$nextTick(function() {
							self.loadChart();
						});
						self.loading = false;
					})
					.catch(function(err) {
						self.loading = false;
					});
				} else {
					AdminUtils.userCost(this.user.user_id, start, end)
					.then(function(result) {
						self.cost = result;
						self.$nextTick(function() {
							self.loadChart();
						});
						self.loading = false;
					})
					.catch(function(err) {
						self.loading = false;
					});
				}
			},
			loadChart() {
				const report = this.cost.time_report;
				const datasetsEntries = {};
				const labels = [];
				var entries;
				var keyFormatted;
				for(const [key, values] of Object.entries(report)) {
					keyFormatted = moment(key).format('MMM DD');
					labels.push(keyFormatted);
					for(const [keyValue, value] of Object.entries(values)) {
						if(datasetsEntries[keyValue] == null) {
							datasetsEntries[keyValue] = {
								'values' : [],
								'usage' : []
							};
						}
						entries = datasetsEntries[keyValue];
						entries['values'].push({x:keyFormatted,y:value['total'].toFixed(2)});
						entries['usage'].push(value['usage'].toFixed(2)+" "+value['unit']);
					}
				}
				const datasets = [];
				var i = 0;
				for(const [key, values] of Object.entries(datasetsEntries)) {
					datasets.push({
						label : key,
						data : values['values'],
						fill : false,
						backgroundColor : this.colorDataMap[key]
					});
					i++;
				}
				const ctx = document.getElementById('time_chart');
				const data = {
					labels: labels,
					datasets: datasets
				};

				const config = {
					type: 'bar',
					data: data,
					options: {
						scales: {
							x: {
								stacked:true,
							},
							y: {
								stacked: true,
								ticks: {
									callback: function(value, index, values) {
										return '$'+value;
									}
								}
							},
						},
						plugins: {
							tooltip: {
								callbacks: {
									label: function(tooltip) {
										var cost = datasetsEntries[tooltip.dataset.label]['values'][tooltip.dataIndex].y;
										var usage = datasetsEntries[tooltip.dataset.label]['usage'][tooltip.dataIndex];
										return ['Cost: $'+cost,'Usage: '+usage];
									}
								}
							}
						}
					}
				};

				const myChart = new Chart(ctx, config);
			},
			charge() {
				var self = this;
				bootbox.prompt({
					title: "How much do you want to charge?",
					centerVertical: true,
					inputType: 'number',
					callback: function (result) {
						if(result) {
							blockElement('body');
							AdminUtils.chargeUser(self.userId, result)
							.then(function() {
								unblockElement('body');
								bootbox.alert("Credits added successfully");

								if(self.reloadCallback)
									self.reloadCallback();
							})
							.catch(function(error) {
								unblockElement('body');
								bootbox.alert(error.error);
							});
						}
					}
				});
			},
			changeEnvironment() {
				this.$refs.environmentSetup.show(this.userId,this.griffinVars);
			},
			changeInstance() {
				this.$refs.changeInstance.show(this.userId);
			},
			downgradeUser() {
				var self = this;
				blockElement('body');
				AdminUtils.downgradeUser(this.userId)
				.then(function() {
					unblockElement('body');
					bootbox.alert("User drowngraded with successfully");

					if(self.reloadCallback)
						self.reloadCallback();
				})
				.catch(function(error) {
					unblockElement('body');
					bootbox.alert(error.error);
				});
			},
			upgradeUser() {
				var self = this;
				blockElement('body');
				AdminUtils.upgradeUser(this.userId)
				.then(function() {
					unblockElement('body');
					bootbox.alert("User upgraded with successfully");

					if(self.reloadCallback)
						self.reloadCallback();
				})
				.catch(function(error) {
					unblockElement('body');
					bootbox.alert(error.error);
				});
				
			}
		}
	}
</script>