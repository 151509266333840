import Vue from "vue";
import App from "./App.vue";
import { Bootstrap } from 'bootstrap'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
import 'vue2-datepicker/index.css';

import jquery from 'jquery'
window.$ = jquery;
import {blockElement, unlockElement} from '@/utils/blockui.js'; 
window.blockElement = blockElement;
window.unblockElement = unlockElement;

import '@/assets/css/main.css'
import '@/assets/css/theme.css'

import * as moment from 'moment'
window.moment = moment;

import * as Utils from '@/utils/utils.js'
window.Utils = Utils;

Vue.config.productionTip = false

import router from './router'
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
