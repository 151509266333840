<template>
    <div class="fill container-fluid p-0">
        <div id="register-card" class="card mx-auto mt-5" style="width:390px;">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="icon_button" @click="back()">
                        <i class="fa fa-angle-left"></i>
                    </div>
                    <h5 class="card-title mb-2">Before continue, set a new password</h5>
                </div>

                <div class="alert alert-danger" v-if="error">
                    {{ error }}
                </div>
                <form class="mt-2">
                    <div class="form-group">
                        <strong>Current Password</strong>
                        <input v-model="old_password" type="password" class="form-control mb-1">
                    </div>
                    <div class="form-group">
                        <strong>Enter your new Password</strong>
                        <input v-model="new_password" type="password" class="form-control mb-1">
                        <small class="form-text text-muted">at least 8 characters with lowercase, uppercase and special characters</small>

                    </div>
                    <button type="button" class="btn btn-success mt-2" @click="confirm">Confirm</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

    import * as AccountUtils from '@/utils/accountUtils.js'

    export default {
        name: 'ChangePassword',
        data:() => {
            return {
                old_password: '',
                new_password: '',
                email: null,
                error : '',
            }
        },
        mounted() {
            if(this.$route.params.email)
                this.email = this.$route.params.email;
            else
                this.back();
        },
        methods: {
            back() {
                this.$router.push({ name : 'Login' });
            },
            confirm() {
                var self = this;
                this.error = null;

                blockElement('#register-card');
                AccountUtils.setPassword(this.email, this.old_password, this.new_password)
                .then(function() {
                    unblockElement('#register-card');
                    self.$router.push({ name : 'Main' });
                })
                .catch(function(error) {
                    unblockElement('#register-card');
                    self.error = error.error;
                });
            },
        }
    }
</script>