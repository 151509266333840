<template>
	<div id="user-detail-modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">User detail - <span v-if="user">{{user.user_email}}</span></h5>
					<button type="button" class="close" @click="hide()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<userBilling :user="user" :admin="true" :reloadCallback="reload"></userBilling>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import userBilling from '@/components/userBilling'

	export default {
		name:'userDetail',
		components:{userBilling},
		data() {
			return {
				user:null,
				reloadCallback:null
			}
		},
		methods: {
			show(user,reloadCallback=null) {
				$('#user-detail-modal').modal("show");
				this.user = user;
				this.reloadCallback = reloadCallback;
			},
			hide() {
				$('#user-detail-modal').modal("hide");
			},
			reload() {
				if(this.reloadCallback) {
					var self = this;
					this.reloadCallback(this.user)
					.then(function(user) {
						self.user = user;
					});
				}
			}
		}
	}
</script>